import React, {useEffect} from 'react';

import './App.scss';
import {Content} from './comps/Content';
import {LayerContainer} from './comps/LayerContainer';
import {Loader} from './comps/Loader';
import {ThreeScene} from './comps/ThreeScene';
import {useSlideCtrl} from './comps/useSlideCtrl';
import {SceneEventType} from './scene/SceneEvent';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';


gsap.registerPlugin(ScrollToPlugin);


export const ApplicationContext = React.createContext(null);


function App() {
  const {currentSide, closeLayer, setCurrentSide, openLayer, layerOpen, isMoving, setIsMoving, showLegend, setShowLegend} = useSlideCtrl();

  const showLoader = true;

  useEffect(() =>
  {
    if(!showLoader)
    {
      const event = new Event(SceneEventType.START);
      window.dispatchEvent(event);
    }
  }, [showLoader]);


  return (
    <ApplicationContext.Provider value={{
      currentSide,
      setCurrentSide,
      openLayer,
      layerOpen,
      isMoving,
      setIsMoving,
      showLegend,
      setShowLegend, closeLayer
    }}
    >
      <div className="appContainer">
        <ThreeScene />
        <Content />
        {showLoader ? <Loader /> : null}
        <LayerContainer />
      </div>
    </ApplicationContext.Provider>
  );
}

export default App;
