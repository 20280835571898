import {gsap} from 'gsap';
import * as THREE from 'three';
import {HexagonTile} from './HexagonTile';


class HexagonGrid
{
  object = new THREE.Group();
  gridGroup = new THREE.Group();
  tiles = [];
  goBackTile;
  tick = 0;

  iconList = [
    '3_IOT',
    '2_AI-01',
    '4_Cyber_Security',
    '6_Infrastructure_Software',
    '7_Software_defined_Hardware',
    '1_Application',
    '5_on_Demand',
    '4_Cyber_Security',
    '6_Infrastructure_Software',
    '7_Software_defined_Hardware',
    '1_Application',
    '5_on_Demand'
  ];

  txtSVGList = [
    'tile_IOT',
    'tile_AI',
    'tile_CS',
    'tile_Next',
    'tile_SPHW',
    'tile_IOT',
    'tile_AI',
    'tile_CS',
    'tile_Next',
    'tile_SPHW',
    'tile_IOT',
    'tile_AI'
  ];


  //  iconList = [
  //    '1_Application',
  //    '2_AI',
  //    '3_IOT',
  //    '4_Cyber_Security',
  //    '5_on_Demand',
  //    '6_Infrastructure_Software',
  //    '7_Software_defined_Hardware'
  //  ];

  constructor() {
    this.drawGrid();

    const pivot = new THREE.Group();
    const box = new THREE.Box3().setFromObject(this.gridGroup);
    const axisHelp = new THREE.AxesHelper(3);
  }

  drawGrid() {
    const numItems = 12;
    const radDelta = 360 / numItems;
    const radius = 2;


    let colCount = 0;
    let rowCount = 0;
    for (let i = 0; i < numItems; i++)
    {
      colCount++;
      let tile = new HexagonTile(i, this.iconList[i], this.txtSVGList[i], 'dark', 0, '#ffffff');
      const obj = tile.object;

      const x = colCount * HexagonTile.size * 2;
      const z = rowCount * HexagonTile.size * 2 + (colCount % 2) * .5;

      obj.position.x = x;
      obj.position.z = z;
      //      obj.position.y = (rowCount * .2);

      if(colCount === 4)
      {
        colCount = 0;
        rowCount++;
      }
      this.gridGroup.add(obj);
      this.tiles.push(tile);

    }


    new THREE.Box3().setFromObject(this.gridGroup).getCenter(this.gridGroup.position).multiplyScalar(-1);
    //    this.gridGroup.add(new THREE.AxesHelper(2));


    //    for (let i = 0; i < numItems; i++)
    //    {
    //      let tile;
    //
    //      const color = (i < 3) ? 'dark' : 'light';
    //      const svgColor = (i < 3) ? '#ffffff' : '#000000';
    //
    //      const angle = (360 / numItems);
    //      tile = new HexagonTile(i, this.iconList[i], this.txtSVGList[i], color, angle * i, svgColor);
    //
    //
    //      const object = tile.object;
    //
    //
    //      const x = radius * Math.cos((angle * i + 72) * Math.PI / 180);
    //      const z = radius * Math.sin((angle * i + 72) * Math.PI / 180);
    //
    //      object.position.x = x;
    //      object.position.z = z;
    //
    //      this.gridGroup.add(object);
    //      this.tiles.push(tile);
    //
    //    }
    this.object.add(this.gridGroup);


    //Go Back Tile
    this.goBackTile = new HexagonTile('back', 'exit');
    const object = this.goBackTile.object;
    object.scale.multiplyScalar(.5);
    object.position.x = 8 * Math.cos(12 * Math.PI / 180);
    object.position.z = 8 * Math.sin(12 * Math.PI / 180);
    object.position.y = -.1;
    this.object.add(this.goBackTile.object);

    object.scale.multiplyScalar(.01);

  }


  activate(activeID) {
    const tl = gsap.timeline();
    let result = null;

    for (let i = 0; i < this.tiles.length; i++)
    {


      const tile = this.tiles[i];
      tile.iconRotation = false;
      tile.svgIcon.rotation.z = 0;
      if(tile.id === activeID)
      {
        //        tl.add(tile.showAnimation(), 0);
        if(!tile.isActive)
        {
          tile.showAnimation();
          result = activeID;

        }
        else
        {
          result = null;
          tile.hideAnimation();
        }

      }
      else
      {
        if(tile.isActive)
        {
          //          tl.add(tile.hideAnimation(), 0);
          tile.hideAnimation();
        }
      }
    }
    return result;
    //    return tl;
  }


  showGridAni() {
//    gsap.killTweensOf([this.tiles.map((tile) => tile.label.scale)]);
    const tl = gsap.timeline();

    this.tiles.forEach(tile =>
    {
      tile.isActive = false;
      //      tile.svgIcon.rotation.y = 1;
    });

    tl.add([
      gsap.to(this.tiles.map((tile) => {return tile.cylinder.rotation;}),
        {z: '+=' + Math.PI, duration: .4, stagger: .2})
    ]);

//      tl.add([
//        gsap.to(this.tiles.map((tile) => {return tile.label.scale;}),
//          {x: 1, y: 1, z: 1, duration: .4, stagger: .2})
//
//      ], .2);


    return tl;
  }

  hideGridAni() {

//    gsap.killTweensOf([this.tiles.map((tile) => tile.label.scale)]);

    const tl = gsap.timeline();

    const labelScale = 0.01;

    //    this.tiles.forEach(tile =>
    //    {
    //      tile.isActive = false;
    //      tile.svgIcon.rotation.z = 0;
    //    });


//    tl.add([
//      gsap.to(this.tiles.map((tile) => {return tile.label.scale;}),
//        {x: labelScale, y: labelScale, z: labelScale})
//    ], 0);

    tl.add([
      gsap.to(this.tiles.map((tile) => {return tile.cylinder.rotation;}),
        {z: 0, duration: 0})
    ], 0);

    return tl;
  }


  renderTick(campos, angle) {

    //    const angle = angle * Math.PI / 180;
    //    const angle = angle * Math.PI / 180;


    this.goBackTile.object.position.x = 4.1 * Math.cos(-(angle + Math.PI * .2));
    this.goBackTile.object.position.z = 4.1 * Math.sin(-(angle + Math.PI * .2));


    for (let i = 0; i < this.tiles.length; i++)
    {
      const tile = this.tiles[i];
      const icon = tile.svgIcon;

      //      icon.rotation.y = angle;


      if(tile.iconRotation)
      {
        tile.svgIcon.rotation.z += .01;
      }
      tile.object.rotation.y = angle;

      tile.animate(campos);
    }

    this.goBackTile.object.rotation.y = angle;
  }
}

export {HexagonGrid};
