import * as THREE from 'three';


class ParticleWaves
{
  object = new THREE.Group();
  pGroup;

  particles;
  count = 0;
  pAmount = 30;
  pSpace = 1.5;

  constructor()
  {
    this.initParticles();
  }


  initParticles()
  {
    let particleAmount = this.pAmount;
    let space = this.pSpace;
    let numParticles = particleAmount * particleAmount;

    let positions = new Float32Array(numParticles * 3);
    let scales = new Float32Array(numParticles);

    let i = 0, j = 0;

    //    let masterGeometry = new THREE.BufferGeometry();
    //    const geometry = new THREE.InstancedBufferGeometry();
    //    geometry.instanceCount = particleAmount;

    for (let ix = 0; ix < particleAmount; ix++)
    {

      for (let iy = 0; iy < particleAmount; iy++)
      {

        positions[i] = ix * space - ((particleAmount * space) / 2); // x
        positions[i + 1] = 0; // y
        positions[i + 2] = iy * space - ((particleAmount * space) / 2); // z

        scales[j] = 1;

        i += 3;
        j++;

      }

    }

    let geometry = new THREE.BufferGeometry();
    this.geometry = geometry;

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('scale', new THREE.BufferAttribute(scales, 1));

    let material = new THREE.ShaderMaterial({

      uniforms      : {
        color      : {value: new THREE.Color(0xffffff)},
        opacity    : .2,
        transparent: true
      },
      vertexShader  : document.getElementById('vertexshader').textContent,
      fragmentShader: document.getElementById('fragmentshader').textContent

    });

    //

    this.particles = new THREE.Points(geometry, material);

    this.pGroup = new THREE.Group();
    this.particles.fog = true;
    this.pGroup.position.y = 0;
    this.pGroup.position.y = -.2;
    this.pGroup.add(this.particles);
    this.pGroup.scale.multiplyScalar(0.25);


    //    this.pGroup.rotation.x = 5 * Math.PI / 180;


    this.object.add(this.pGroup);
  }

  animate()
  {

    //    return;

    let positions = this.particles.geometry.attributes.position.array;
    let scales = this.particles.geometry.attributes.scale.array;

    let scaleVal = .6 * window.devicePixelRatio;
    let posVal = .4;

    let i = 0, j = 0;

    for (let ix = 0; ix < this.pAmount; ix++)
    {

      for (let iy = 0; iy < this.pAmount; iy++)
      {

        positions[i + 1] = (Math.sin((ix + this.count) * 0.3) * posVal) +
          (Math.sin((iy + this.count) * 0.5) * posVal);

        scales[j] = (Math.sin((ix + this.count) * 0.3) + 1) * scaleVal +
          (Math.sin((iy + this.count) * 0.5) + 1) * scaleVal;

        i += 3;
        j++;

      }

    }

    this.particles.geometry.attributes.position.needsUpdate = true;
    this.particles.geometry.attributes.scale.needsUpdate = true;

    this.count += 0.1;
  }

  dispose()
  {

    this.object.remove(this.pGroup);
    this.object.remove(this.particles);
    this.particles.clear();
    this.pGroup.clear();
    this.object.clear();

    this.pGroup = null;
    this.object = null;
  }

}

export {ParticleWaves};