import React, {useEffect, useRef} from 'react';
import {Scene} from '../scene/Scene';


console.log('new THreeSceneReact');
const myScene = new Scene();

const ThreeScene = (props) =>
{
  //  const {setCurrentSide, openLayer, layerOpen, setIsMoving, setShowLegend} = useContext(ApplicationContext);

  //  const [animate, setAnimate] = useState(false);

  const refCanvas = useRef(null);

  //  let scene;

  useEffect(() =>
  {
    console.log('setDom', refCanvas);
    if(refCanvas)
    {
      myScene.setDomEl(refCanvas.current);
    }
  }, [refCanvas]);


  //ThreeJS Events
  //  useEffect(() =>
  //  {
  //    window.addEventListener(SceneEventType.SIDE_CHANGE, (e) =>
  //    {
  //      console.log('some change', e.data.currentSide);
  //      const side = e.data.currentSide;
  //      setCurrentSide(side);
  //    });
  //
  //    window.addEventListener(SceneEventType.OPEN_LAYER, e =>
  //    {
  //      openLayer(e.data.side);
  //    });
  //
  //    window.addEventListener(SceneEventType.MOVE_START, e =>
  //    {
  //      //      console.log('isMoving', true);
  //      setIsMoving(true);
  //    });
  //
  //    window.addEventListener(SceneEventType.MOVE_END, e =>
  //    {
  //      //      console.log('isMoving', false);
  //      setIsMoving(false);
  //    });
  //
  //    window.addEventListener(SceneEventType.SHOW_LEGEND, e =>
  //    {
  //      setShowLegend(true);
  //    });
  //    window.addEventListener(SceneEventType.HIDE_LEGEND, e =>
  //    {
  //      setShowLegend(false);
  //    });
  //
  //  }, [setCurrentSide, openLayer, setIsMoving]);


  //  useEffect(() =>
  //  {
  //    if(layerOpen)
  //    {
  //      myScene.disableCtrl();
  //      console.log('layerOpen');
  //    }
  //    else
  //    {
  //      myScene.enableCtrl();
  //      console.log('layerClose');
  //    }
  //  }, [layerOpen]);


  return (
    <>
      <div className={'canvasCont'} ref={refCanvas} />
    </>
  );
};

export {ThreeScene};


