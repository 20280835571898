import {gsap} from 'gsap';
import React, {useEffect, useRef} from 'react';
import {ReactComponent as MouseClick} from '../assets/mouseClick.svg';
import {SceneEventType} from '../scene/SceneEvent';
import data from './../data';
import {GlitchText} from './GlitchText';
import {usePreloadImages} from './hooks/usePreloadImages';
import {StartButton} from './StartButton';
import {UXLabLogo} from './UXLabLogo';


const Loader = (props) =>
{
  const refMouse      = useRef(null);
  const refMouseClick = useRef(null);
  const refText1      = useRef(null);
  const refText2      = useRef(null);
  const refLoader     = useRef(null);
  const refLoaderText = useRef(null);
  const refStartBtn   = useRef(null);

  const {isLoading, loadedItems, percent} = usePreloadImages();


  const timeline = useRef(gsap.timeline({repeat: -1}));


  useEffect(() =>
  {
    const tl = timeline.current;

    tl.add([
      gsap.to(refText1.current, {autoAlpha: 1, duration: .5}),
      gsap.to(refMouseClick.current, {autoAlpha: 1, duration: .5})
    ]);

    const moveTl = gsap.timeline();
    moveTl.add(gsap.to(refMouseClick.current, {x: 40, duration: .5, ease: 'Cubic.easeOut'}));
    moveTl.add(gsap.to(refMouseClick.current, {x: -40, duration: .5, ease: 'Cubic.easeOut'}), '+=.5');
    moveTl.add(gsap.to(refMouseClick.current, {x: 0, duration: .5, ease: 'Cubic.easeOut'}), '+=1');
    tl.add(moveTl);

    tl.add([
      gsap.to(refText1.current, {autoAlpha: 0, duration: .3})
    ]);
    tl.add([
      gsap.to(refText2.current, {autoAlpha: 1, duration: .3})
    ]);

    const clickTl = gsap.timeline({repeat: 2, repeatDelay: 1});
    clickTl.add(gsap.to(refMouseClick.current, {scale: .8, duration: .5}));
    clickTl.add(gsap.to(refMouseClick.current, {scale: 1, duration: .5}));
    tl.add(clickTl);

    tl.add([
      gsap.to(refText2.current, {autoAlpha: 0, duration: .3}),
      gsap.to(refMouseClick.current, {autoAlpha: 0, duration: .5})
    ]);


  }, [refMouse, refMouseClick, refText1, refText2, timeline]);


  const hideLoader = () =>
  {
    timeline.current.kill();
    gsap.to(refLoader.current, {top: '-100%', duration: 1, ease: 'Expo.easeInOut'});

    const event = new Event(SceneEventType.START);
    window.dispatchEvent(event);
  };

  useEffect(() =>
  {
    if(isLoading === false)
    {
      gsap.to(refLoaderText.current, {autoAlpha: 0, duration: .3});
    }
  }, [isLoading, refLoaderText]);


  return (
    <div className={'appLoader'} ref={refLoader}>
      <div className={'background'}>
      </div>
      <a target={'_blank'} className={'powerdBy'} href={'https://social.intra.corpintra.net/community/company/it-en/it-systems-service-and-support/lux-en'}>
        <div>{data.misc.created}</div>
        <UXLabLogo />
      </a>
      <div className={'inlay'}>
        <GlitchText text={'What if...'} />
        <div className={'ani'}>
          <MouseClick className={'mouseIcon'} ref={refMouseClick} />
          <div className={'text'}>
            <p ref={refText1}>Use your mouse to rotate the scene...</p>
            <p ref={refText2}>...and click on the elements you want to learn more about.</p>
          </div>
        </div>
        <p ref={refLoaderText}>Loading ... {percent}%</p>
        <StartButton onClick={hideLoader} show={isLoading === false} caption={data.buttons.start} hoverColor={'#ffffff'} />
      </div>

    </div>
  );
};

export {Loader};
