import * as THREE from 'three';
import {SVGLoader} from './SVGLoader';


const GlobalMaterial = new THREE.MeshBasicMaterial({
  //            color     : path.color,
  color     : '#ffffff',
  side      : THREE.DoubleSide,
  depthWrite: true,
  depthTest : true
});

export const CheckMaterial = new THREE.MeshBasicMaterial({
  //            color     : path.color,
  color     : '#00ff00',
  side      : THREE.DoubleSide,
  depthWrite: true,
  depthTest : true
});


class SVGIcon
{

  object = new THREE.Group();
  svgObj;


  name;
  svgURL;
  material;


  align;
  rotation;

  constructor(svgURL = 0, name = 'noName', align = 'center', rotation = 'horizontal', color = '#ffffff', callback, material) {
    this.color = color;
    this.align = align;
    this.rotation = rotation;
    this.callback = callback;
    this.name = name;
    this.svgURL = svgURL;
    if(material)
    {
      this.material = material;

    }else{
      this.material = GlobalMaterial;
    }

    this.loadSVG();
  }

  loadSVG() {

    //    this.material = new THREE.MeshBasicMaterial({
    //      //            color     : path.color,
    //      color     : this.color,
    //      side      : THREE.DoubleSide,
    //      depthWrite: true,
    //      depthTest : true
    //    });


    const loader = new SVGLoader();

    const svgObj = new THREE.Group();
    svgObj.scale.multiplyScalar(.005);
    svgObj.name = 'test';
    this.svgObj = svgObj;

    const name = this.name;


    // load a SVG resource
    loader.load(
      // resource URL
      './svg/' + this.svgURL + '.svg',
      // called when the resource is loaded
      (data) => this.onLoaded(data),
      // called when loading is in progresses
      function (xhr)
      {
      },
      // called when loading has errors
      function (error)
      {
        console.log('An error happened');
      }
    );
  }

  onLoaded(data) {
    const paths = data.paths;
    const group = new THREE.Group();

    for (let i = 0; i < paths.length; i++)
    {

      const path = paths[i];


      const shapes = SVGLoader.createShapes(path);

      for (let j = 0; j < shapes.length; j++)
      {

        const shape = shapes[j];
        const geometry = new THREE.ShapeBufferGeometry(shape);
        const mesh = new THREE.Mesh(geometry, this.material);
        mesh.castShadow = false;
        mesh.name = this.name;
        group.add(mesh);

      }


    }

    new THREE.Box3().setFromObject(group).getCenter(group.position).multiplyScalar(-1);
    this.svgObj.add(group);
    this.svgObj.rotation.x = Math.PI / 2;

    this.alignment();


    if(this.callback)
    {
      this.callback();
    }


  }

  alignment() {
    const alignGroup = new THREE.Group();
    alignGroup.name = this.name;
    alignGroup['groupType'] = 'align';

    alignGroup.add(this.svgObj);

    switch (this.rotation)
    {
      default:
      case 'horizontal':
        break;
      case 'vertical':
        alignGroup.rotation.x = Math.PI / 2;
        break;
    }

    const pivotGroup = new THREE.Group();
    pivotGroup.name = this.name;
    pivotGroup['groupType'] = 'pivot';

    switch (this.align)
    {
      default:
      case 'center':
        break;
      case 'bottom':
        const box = new THREE.Box3().setFromObject(this.svgObj);
        const height = box.max.z - box.min.z;
        alignGroup.position.y = height / 2;
        break;
    }


    pivotGroup.add(alignGroup);
    this.object.add(pivotGroup);

  }

}

export {SVGIcon};
