import gsap from 'gsap';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useRef} from 'react';
import {ApplicationContext} from '../../App';
import {GlitchText} from '../GlitchText';
import {useClusterColor, useClusterHeadline} from '../hooks/useClusterColor';
import {StartButton} from '../StartButton';
import {UXLabLogo} from '../UXLabLogo';


const SideHex = (({data, onClick}, ref) =>
{
  const refText = useRef();
  const {openLayer, currentSide, layerOpen} = useContext(ApplicationContext);
  const hoverColor = useClusterColor(data.cluster);
  const clusterHeadline = useClusterHeadline(data.cluster);

  //  useImperativeHandle(ref, () => ({
  //    content: refText.current
  //  }));

  const onClickBtn = () =>
  {
    openLayer(data.id);
  };

  //init
  useEffect(() =>
  {
    if(refText)
    {
      gsap.set(refText.current, {autoAlpha: 0});
    }
  }, [refText]);

  const clusterName = (data.cluster) ? ' ' + data.cluster : '';


  return (
    <>
      <div className={'side side' + data.id + clusterName} ref={refText}>
        <div className={'text'}>
          <pre style={{color: hoverColor}}>// {clusterHeadline}</pre>
          <GlitchText text={data.title} animate={data.id === currentSide} />
          <h2>{data.shortDesc}</h2>
          {
            data.layer !== undefined ? <StartButton caption={'LEARN MORE'} onClick={onClickBtn} hoverColor={hoverColor} /> : null
          }
        </div>
      </div>
    </>
  );
});

SideHex.propTypes = {
  data: PropTypes.object
};

export {SideHex};
