import {useEffect, useState} from 'react';
import data from './../../data';


const ImagesList = [
  //  './data/hexagon/text_OP.svg',
  //  './data/hexagon/noun_smart_3394309.svg',
  //  './data/hexagon/text_int_aps.svg',
  //  './data/hexagon/noun_digital_2995235.svg',
  //  './data/hexagon/text_DP.svg',
  //  './data/hexagon/car.svg',
  //  './data/hexagon/truck.svg',
  //  './data/hexagon/application.svg',
  //  './data/hexagon/digital.svg',
  //  './data/hexagon/bus.svg',
  //  './data/hexagon/office.svg',
  //  './data/hexagon/3_IOT.svg',
  //  './data/hexagon/tile_IOT.svg',
  //  './data/hexagon/2_AI-01.svg',
  //  './data/hexagon/tile_AI.svg',
  //  './data/hexagon/4_Cyber_Security.svg',
  //  './data/hexagon/tile_CS.svg',
  //  './data/hexagon/6_Infrastructure_Software.svg',
  //  './data/hexagon/tile_Next.svg',
  //  './data/hexagon/7_Software_defined_Hardware.svg',
  //  './data/hexagon/tile_SPHW.svg',
  //  './data/hexagon/exit.svg'
];

const FontLis = [
  './fonts/daimler-cs-bold-woff'
];

const usePreloadImages = () =>
{

  const [isLoading, setIsLoading]     = useState(true);
  const [totalItems, setTotalItems]   = useState(0);
  const [loadedItems, setLoadedItems] = useState(0);
  const [percent, setPercent]         = useState(0);

  useEffect(() =>
  {
    const pages = data.pages;

    const loadItems = new Set();

    data.pages.forEach(page =>
    {
      if(page.layer && page.layer.headerImg)
      {
        loadItems.add(page.layer.headerImg);
      }
    });

    setTotalItems(Array.from(loadItems).length);


    console.log('start loading', loadItems);
    const preloadImages = async(images) =>
    {
      const promisses = await Array.from(loadItems).map((src) =>
      {

        const promise = new Promise((resolve, reject) =>
        {
          const img   = new Image();
          img.src     = src;
          img.onload  = resolve;
          img.onerror = reject;

          return src;
        });

        promise.then((e) =>
        {
          console.log('load a image', e.path[0].currentSrc);
          setLoadedItems((prev) => prev + 1);
        });

        return promise;

      });

      await Promise.all(promisses).then(() =>
      {
        console.log('all Loaded');
        setIsLoading(false);
      });
    };

    const promise = preloadImages(ImagesList);

  }, []);

  //Set Percentage
  useEffect(() =>
  {
    console.log(totalItems);
    if(totalItems)
    {
      setPercent(loadedItems / totalItems * 100);
    }
    if(totalItems === loadedItems)
    {
      setIsLoading(false);
    }
  }, [loadedItems, totalItems]);

  //  useEffect(()=>{
  //    console.log('start fontLoading');
  //    const preloadFont = async(images) =>
  //    {
  //      const promisses = await images.map((src) =>
  //      {
  //        return new Promise((resolve, reject) =>
  //        {
  //          const img = new File(src);
  //          img.src = src;
  //          img.onload = resolve;
  //          img.onerror = reject;
  //        });
  //      });
  //
  //      await Promise.all(promisses);
  //      console.log('all Loaded');
  //
  //      setIsLoading(false);
  //    };
  //
  //    const promise = preloadFont(ImagesList);
  //
  //  },[])


  return {isLoading, loadedItems, percent};


};

export {usePreloadImages};