import React from 'react';
import {useClusterColor, useClusterHeadline} from '../hooks/useClusterColor';


const LayerHeader = (props) =>
{
  const hoverColor = useClusterColor(props.pageData.cluster);
  const clusterHeadline = useClusterHeadline(props.pageData.cluster);


  return (
    <div className={'layerHeader'}>
      <pre style={{color: hoverColor}}>// {clusterHeadline}</pre>
      <h1>{props.pageData.title}</h1>
    </div>
  );
};


export {LayerHeader};
