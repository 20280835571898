import gsap from 'gsap';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {ApplicationContext} from '../App';

import data from './../data.json';
import {SideHex} from './slides/SideHex';
import {UXLabLogo} from './UXLabLogo';


const Content = (props) =>
{
  const {currentSide} = useContext(ApplicationContext);


  //  useEffect(() =>
  //  {
  //    if(activeSide === currentSide)
  //    {
  //      return;
  //    }
  //    else
  //    {
  //      setActiveSide(currentSide);
  //    }
  //
  //
  //    let allText = allRefs.map((item) =>
  //    {
  //      return item.current.text;
  //    });
  //
  //
  //
  //    let showText, showKPIs, showRef, showPartners;
  //
  //    console.log('showSlide', currentSide);
  //
  //    switch (currentSide)
  //    {
  //      case 'hex0':
  //        showRef = refSideHex;
  //        break;
  //      case 'hex1':
  //        showRef = refSideHex;
  //        break;
  //      case 'hex2':
  //        showRef = refSideHex;
  //        break;
  //      case 'hex3':
  //        showRef = refSideHex;
  //        break;
  //      case 'hex4':
  //        showRef = refSideHex;
  //        break;
  //      case 'layer1':
  //        showRef = refSideHex;
  //        break;
  //      case 'layer2':
  //        showRef = refSideHex;
  //        break;
  //      case 'intro':
  //        showRef = refIntro;
  //        break;
  //
  //      default :
  //        showRef = null;
  //    }
  //    if(showRef)
  //    {
  //      showText     = showRef.current.text;
  //      showPartners = showRef.current.partners;
  //    }
  //
  //
  //    TweenMax.killTweensOf([allText]);
  //
  //    const tl = gsap.timeline();
  //
  //    tl.add([
  //      TweenMax.to(allText, .3, {opacity: 0, x: 100}),
  //    ]);
  //    tl.add('moveIn');
  //    if(showText)
  //    {
  //
  //      tl.add([
  //        TweenMax.fromTo(showText, .3, {x: -100, opacity: 0},
  //          {opacity: 1, x: 0, ease: Sine.easeOut}
  //        )]);
  //    }
  //
  //  }, [activeSide, allRefs, currentSide]);

  //  useEffect(() =>
  //  {
  //    if(initStage)
  //    {
  //      setInitStage(false);
  //      let allText = allRefs.map((item) =>
  //      {
  //        return item.current.text;
  //      });
  //
  //
  //
  //      TweenMax.set(allText, {opacity: 0, x: 100});
  //    }
  //  }, [allRefs, initStage]);


  const [pageElements, setPageElements] = useState();
  const [sideObjs, setSideObjs] = useState();


  //  useEffect(() =>
  //  {
  //
  //    if(data)
  //    {
  //
  //
  //      const pages = data.pages;
  //      console.log('create pages');
  //      console.log(pages);
  //
  //      let pageRefs = [];
  //      let sideObjs = [];
  //
  //      console.log('fick die hennnnnne');
  //      const elements = pages.map((pageData, i) =>
  //      {
  //
  //
  //        const ref = React.createRef();
  //        pageRefs.push(ref);
  //
  //        const obj = {};
  //        obj.el = <SideHex key={i} data={pageData} onClick={() => {console.log('fick scheisse', pageData);}} ref={ref} />;
  //        obj.id = pageData.id;
  //        obj.ref = ref;
  //
  //        sideObjs.push(obj);
  //
  //        return obj.el;
  //      });
  //
  //      setPageElements(elements);
  //      setSideObjs(sideObjs);
  //
  //    }
  //
  //  }, []);


  //  useEffect(() =>
  //  {
  //    let tl = gsap.timeline({});
  //
  //    let hideTweens = [];
  //    let showTween = [];
  //
  //    if(sideObjs)
  //    {
  //
  //      sideObjs.forEach(side =>
  //      {
  //        if(side.id === currentSide)
  //        {
  //          showTween.push(gsap.to(side.ref.current.content, {opacity: 1}));
  //        }
  //        else
  //        {
  //          hideTweens.push(gsap.to(side.ref.current.content, {opacity: 0}));
  //        }
  //      });
  //
  //      tl.add(hideTweens);
  //      tl.add(showTween);
  //
  //    }
  //
  //  }, [currentSide, sideObjs]);


  const [sides, setSides] = useState();
  const refContainer = useRef(null);

  useEffect(() =>
  {
    if(data)
    {

      const pages = data.pages;

      const comps = pages.map((page, i) =>
      {
        return <SideHex key={i} data={page} className={'side' + page.id + ' ' + page.cluster} />;
      });
      console.log('generate pages', comps);
      setSides(comps);
    }


  }, []);

  useEffect(() =>
  {
    if(refContainer)
    {
      let container = refContainer.current;

      let tl = gsap.timeline({});

      let hideTweens = [];
      let showTween = [];

      const all = container.getElementsByClassName('side');
      const current = container.getElementsByClassName('side' + currentSide);
      hideTweens.push(gsap.to(all, {autoAlpha: 0}));
      showTween.push(gsap.to(current, {autoAlpha: 1}));

      tl.add(hideTweens);
      tl.add(showTween);
    }

  }, [currentSide, sideObjs, refContainer]);


  return (
    <>
      <div className={'contentWrp'} style={{}}>
        <div className={'contentCont'} style={{}}>
          <div className={'slides'} ref={refContainer}>
            {sides}
          </div>
          <UXLabLogo />
        </div>
      </div>
    </>
  );
};

export {Content};
