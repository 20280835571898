import {TweenLite} from 'gsap';
import * as THREE from 'three';
import {SideColors} from './Scene';


class Floor
{
  sphereSize = 6;

  object;
  ground;
  sphere;
  color;
  ittPlane;

  floorTween;
  sphereTween;

  constructor(initColor)
  {
    this.object = new THREE.Group();
    this.color = initColor;


    this.initGround();
    this.initFloor();
    //    this.initPlane();

    //    window.addEventListener(SceneEventType.SIDE_CHANGE, (e) => this.onSideChange(e));

  }


  initGround()
  {
    let texture = new THREE.TextureLoader().load('data/radial.jpg');

    const ground = new THREE.Mesh(
      new THREE.CircleBufferGeometry(this.sphereSize, 64),
      new THREE.MeshBasicMaterial({
        blending   : THREE.AdditiveBlending,
        alphaMap   : texture,
        alphaTest  : 0,
        transparent: true,
        color      : this.color
        //        opacity    : .8,
        //        side       : THREE.DoubleSide
      })
    );

    //    ground.receiveShadow = true;
    //    ground.castShadow = true;
    ground.rotation.x -= Math.PI / 2;
    ground.position.y = -1;

    this.ground = ground;
    this.object.add(ground);




  }

  initFloor()
  {

    //    let gradient = new THREE.TextureLoader().load('data/gradient.jpg');
    //    const geometry = new THREE.SphereGeometry(8, 32, 32);
    const geometry = new THREE.DodecahedronBufferGeometry(this.sphereSize, 2);
    //    const material = new THREE.MeshBasicMaterial({transparent: true, opacity: .1, color: 0xffffff, wireframe: true});

    //Gradient
    //    const sphereMat = new THREE.MeshPhongMaterial({
    //      flatShading: true,
    //      opacity    : 1,
    //      transparent: true,
    //      color      : 0xffffff,
    //      shininess  : 5,
    //      metalness  : .5,
    //      roughness  : 0,
    //      map        : gradient
    //    });

    //    let ittTexture = new THREE.TextureLoader().load('data/itt.png');

    const sphereMat = new THREE.MeshPhongMaterial({
      flatShading: true,
      transparent: false,
      color      : 0x000000,
      shininess  : 15
    });
    sphereMat.side = THREE.BackSide;
    //    sphereMat.shadowSide = THREE.DoubleSide;
    //    sphereMat.needsUpdate = true;

    sphereMat.fog = false;
    this.sphere = new THREE.Mesh(geometry, sphereMat);
    this.object.add(this.sphere);


    //    const geometry = new THREE.SphereGeometry(8, 32, 32);
    //    const geometryLines = new THREE.DodecahedronBufferGeometry(4, 2);
    //    const material = new THREE.MeshBasicMaterial({transparent: true, opacity: .1, color: 0xffffff, wireframe: true});
    //    const materialLines = new THREE.MeshBasicMaterial({
    //      opacity    : .1,
    //      transparent: true,
    //      color      : 0xffffff,
    //      wireframe  : true
    //
    //    });

    //    materialLines.fog = true;

    //    let lines = new THREE.Mesh(geometryLines, materialLines);
    //    this.object.add(lines);


  }

  tweenColor(target, color, tween, duration = .3)
  {
    const curColor = target.material.color;
    const newColor = new THREE.Color(color);

    if(curColor.getHexString() !== newColor.getHexString())
    {

      if(tween)
      {
        tween.kill();
      }

      tween = TweenLite.to(curColor, duration,
        {
          r       : newColor.r,
          g       : newColor.g,
          b       : newColor.b,
          onUpdate: () =>
          {
            target.material.color = curColor;
            target.material.needsUpdate = true;
          }
        });
    }

    return tween;
  }

  onSideChange(e)
  {
    const side = e.data.currentSide;
    let tweenColor = SideColors[1];
    switch (side)
    {
      default:
      case 'side1':
        tweenColor = SideColors[0];
        break;
      case 'side2':
        tweenColor = SideColors[1];
        break;
      case 'side3':
        tweenColor = SideColors[2];
        break;
    }

    this.tweenColor(this.ground, tweenColor, this.floorTween);
    //    this.tweenColor(this.sphere, tweenColor, this.sphereTween);
  }

  changeGroundColorTween(targetColor, duration = .3)
  {
    return this.tweenColor(this.ground, targetColor, this.floorTween, duration);
  }


  initPlane()
  {
    let ittTexture = new THREE.TextureLoader().load('data/itt.png');
    let geo = new THREE.PlaneBufferGeometry(3, 3, 5, 5);
    let mat = new THREE.MeshBasicMaterial(
      {
        map : ittTexture,
        side: THREE.DoubleSide
      });

    let mesh = new THREE.Mesh(geo, mat);

    this.ittPlane = mesh;

    //    mesh.position.y = 1.5;
    //    mesh.position.z = -4;

    this.object.add(mesh);
  }

  animate()
  {
    //    this.sphere.rotation.x += .001;
    //    this.sphere.rotation.y += .001;
    //    this.sphere.scale.y -= 0.01;
    //    this.sphere.scale.x -= 0.01;
    //    this.sphere.scale.z -= 0.01;
  }

}

export {Floor};
