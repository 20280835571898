import * as THREE from 'three';
import {getRandomArbitrary} from '../MyUtils';
import data from './../../data';
import {BigHexagon} from './BigHexagon';


class HexagonStage
{

  hexCount = 12;

  object = new THREE.Group();
  hexagons = [];

  constructor() {
    this.createGrid();
  }


  createGrid() {

    let centerGroup = new THREE.Group();

    const a = BigHexagon.SIZE * Math.sin(60 * Math.PI / 180);
    const b = BigHexagon.SIZE * Math.cos(60 * Math.PI / 180);

    const pages = data.pages;

    let colCount = 0;
    let rowCount = 0;
    for (let i = 0; i < pages.length; i++)
    {
      const pageData = data.pages[i];
      if(typeof (pageData.id) === 'number')
      {
        colCount++;
        let hexagon = new BigHexagon('#151515', pageData);
        let hexagonObj = hexagon.object;
        this.hexagons.push(hexagon);

        const x = a * 2 * colCount + ((rowCount % 2) * a);
        const z = rowCount * (BigHexagon.SIZE + b);

        hexagonObj.position.x = x;
        hexagonObj.position.z = z;
        hexagonObj.position.y = getRandomArbitrary(.2, .5);

        if(colCount === 4)
        {
          colCount = 0;
          rowCount++;
        }
        centerGroup.add(hexagonObj);
      }
    }


    //    new THREE.Box3().setFromObject(centerGroup).getCenter(centerGroup).multiplyScalar(-1);
    new THREE.Box3().setFromObject(centerGroup).getCenter(centerGroup.position).multiplyScalar(-1);

    //    const axisHelp = new THREE.AxesHelper(3);
    //    this.object.add(axisHelp);
    this.object.add(centerGroup);
  }

  activate(activeID) {
    console.log('activate', activeID);
    let activeEl = null;
    if(this.activeID !== activeID)
    {
      this.activeID = activeID;

      this.hexagons.forEach((bigHexa, i) =>
      {
        if(bigHexa.id === activeID)
        {
          bigHexa.activate();
          activeEl = bigHexa;
        }
        else
        {
          bigHexa.deactivate();
        }
      });
    }

    return activeEl;
  }

  setLayerViewed(layerID) {
    const tile = this.getTileToLayerID(layerID);
    console.log('got layer viewed event', layerID, tile);
    tile.setViewed();
  }

  getTileToLayerID(layerID) {
    let result = null;
    this.hexagons.forEach((tile) =>
    {
      if(tile.id === layerID)
      {
        result = tile;
      }
    });
    return result;
  }

  animate() {

  }

  renderTick(campos, angle) {


    for (let i = 0; i < this.hexagons.length; i++)
    {
      const hexagon = this.hexagons[i];
      const tile = hexagon.pillar;


      if(tile.iconRotation)
      {
        tile.svgIcon.rotation.z += .01;
      }
      tile.object.rotation.y = angle;

      tile.animate(campos);
    }
  }


}

export {HexagonStage};
