import {gsap} from 'gsap';
import * as THREE from 'three';
import {MaterialCylinderPillar, MaterialCylinderPillarOrange} from './GlobalMaterials';
import {SVGIcon} from './SVGIcon';


class Pillar
{
  object = new THREE.Group();
  rings = new THREE.Group();

  initPos = new THREE.Vector3();

  static cylinderHeight = .1;
  static cylinderWidth = .15;

  isActive = false;
  name;
  size;
  baseColor;

  cylinder;
  pillar;
  svgIcon;
  doRings;

  constructor(name, textSVG, size                                               = .5, baseColor = 0x333333,
              doPillar = true, iconSVG, transparent = false, iconColor, doRings = false) {
    this.iconColor = iconColor;
    this.transparent = transparent;
    this.name = name;
    this.doPillar = doPillar;
    this.doRings = doRings;
    this.textSVG = textSVG;

    this.size = size;
    this.baseColor = baseColor;

    this.object.name = name;


    if(this.doPillar)
    {
      this.initIcon(iconSVG);
    }
    this.initCylinder();
    //    this.initPillar();
    if(doRings)
    {
      this.initRings();
    }
    this.initSVGText();
  }


  initSVGText() {
    if(this.textSVG)
    {

      const icon = new SVGIcon(this.textSVG, this.name);
      this.svgIcon = icon.object;
      this.svgIcon.rotation.y -= Math.PI;
      this.svgIcon.scale.multiplyScalar(this.size * .32 / .8);
      this.svgIcon.position.y = Pillar.cylinderHeight / 2 + .01;
      this.object.add(this.svgIcon);
      this.object.renderOrder = 999;
      this.svgText = icon;
    }
  }

  initIcon(iconSVG) {
    const icon = new SVGIcon(iconSVG, this.name, 'center', 'horizontal', this.iconColor);
    this.pillarIcon = icon.object;

    //    this.pillarIcon.rotation.x = Math.PI / 2;
    this.pillarIcon.scale.multiplyScalar(.6);
    this.pillarIcon.position.y = Pillar.cylinderHeight / 2 + .01;
    this.object.add(this.pillarIcon);
  }


  initCylinder() {
    const size = this.size;
    //    let geoCylinder = new THREE.CylinderBufferGeometry(size, size, Pillar.cylinderHeight, 64);
    const matCylinder = this.baseColor === 'orange' ? MaterialCylinderPillarOrange : MaterialCylinderPillar;

    const geoCylinder = new THREE.CylinderBufferGeometry(size, size, Pillar.cylinderHeight/2, 6, 1, false);


    let cylinder = new THREE.Mesh(geoCylinder, matCylinder);
    cylinder.name = this.name;
    cylinder.castShadow = true;
    cylinder.receiveShadow = true;


    const test = new THREE.Group();
    test.add(cylinder);

    this.cylinder = cylinder;
    this.object.add(test);
  }


  initRings() {
    const radius = 1;
    const fullTorus = this.createTorus(radius, .02, '#333333', Math.PI * 2);
    this.rings.add(fullTorus);

    const halfTorus = this.createTorus(radius, .025, this.baseColor, Math.PI / 2, true);
    halfTorus.rotation.z = Math.PI / 4;
    this.rings.add(halfTorus);

    this.rings.scale.multiplyScalar(.1);


    this.object.add(this.rings);
  }

  createTorus(radius, tube, color, arc, transparent = false, mat) {
    const geometry = new THREE.TorusBufferGeometry(radius, tube, 20, 62, arc);
    const material = new THREE.MeshPhongMaterial({
      color      : color,
      shininess  : 0,
      flatShading: true,
      transparent: transparent,
      opacity    : .8,
      side       : THREE.DoubleSide
    });
    const torus = new THREE.Mesh(geometry, material);
    torus.rotation.x = Math.PI / 2;

    return torus;
  }

  animate(angle) {

    if(this.isActive && this.doRings)
    {
      this.rings.children[1].rotation.z -= .015;
    }

    if(angle)
    {
      //      this.svgText.object.rotation.y = angle;
    }
    //    gsap.to(this.svgText.object.rotation, {y: angle, duration: .2});

  }

  killTweens() {
    gsap.killTweensOf(this.rings.scale, this.rings.rotation);
  }

  showAnimation() {
    this.killTweens();
    const svgSize = .5;
    const ringSize = this.size + .05;

    const tl = gsap.timeline();
    if(!this.isActive)
    {
      this.isActive = true;
      console.log('show', this.name);

      tl.add([
        gsap.to(this.rings.scale, {duration: .3, x: ringSize, y: ringSize, z: ringSize})

      ], 0);

      if(this.doRings)
      {

        tl.add([
          gsap.to(this.rings.rotation, {duration: .7, y: '+=' + (Math.PI * 2), ease: 'Expo.easeOut'})
          //        gsap.to(this.pillar.scale, {duration: .5, y: 2, delay: .2})
        ], .2);
      }

      //      tl.add([
      //        gsap.to(this.svgIcon.scale, {duration: .3, x: svgSize, y: svgSize, z: svgSize})
      //      ], .2);
    }
    return tl;
  }

  hideAnimation() {
    this.killTweens();

    this.isActive = false;
    const tl = gsap.timeline();
    if(this.doRings)
    {

      tl.add([
        gsap.to(this.rings.scale, {duration: .3, x: .01, y: .01, z: .01}),
        gsap.to(this.rings.children[0].rotation, {duration: .3, z: 0})
      ], 0);
    }

    //    tl.add([
    //      gsap.to(this.pillar.scale, {duration: .3, y: 1})
    //    ], 0);


    //    tl.add([
    //      gsap.to(this.svgIcon.scale, {duration: .3, x: .1, y: .1, z: .1})
    //    ], 0);

    return tl;
  }

  setInitPos(vector) {
    this.initPos = vector;
    this.object.position.set(vector.x, vector.y, vector.z);
  }

  tweenToInitPos(duration = .5) {
    const {x, y, z} = this.initPos;
    return gsap.to(this.object.position, {duration: duration, x: x, y: y, z: z, ease: 'Sine.easeOut'});
  }


}

export {Pillar};
