import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {gsap} from 'gsap';

import './GlitchText.scss';


const GlitchText = (props) =>
{
  const refCont = useRef(null);
  const refTop = useRef(null);
  const refBottom = useRef(null);
  const [tl, setTl] = useState();


  useEffect(() =>
  {

    let top = refTop.current;
    let bottom = refBottom.current;
    let text = [top, bottom];

    let tl = gsap.timeline({repeat: -1, repeatDelay: 2});
    tl.to(text, {duration: .1, skewX: 70});
    tl.to(text, {duration: .04, skewX: 0});


    tl.add([
      gsap.to(text, {textShadow: '-3px 0 red', duration: .1}),
      gsap.to(top, {duration: .1, x: -30}),
      gsap.to(bottom, {duration: .2, x: 30})
    ]);

    tl.add([
      gsap.to(text, {textShadow: '-3px 0 green', duration: .01}),
      gsap.to(top, {duration: .05, x: 0}),
      gsap.to(bottom, {duration: .03, x: 0}),
      tl.to(bottom, {duration: .04, skewX: 30, repeat: 1, yoyo: true})
    ]);

    tl.add([
      //      gsap.to(text, {duration: .1, scale: 1.2}),
      gsap.to(text, {textShadow: '0 0 green', duration: .03})
    ]);

    tl.to(text, {duration: .1, skewX: -20});
    tl.to(text, {duration: .04, skewX: 0});

    tl.add([
      gsap.to(text, {textShadow: '-3px 0 red', duration: .1}),
      gsap.to(top, {duration: .1, x: 19}),
      gsap.to(bottom, {duration: .2, x: 10})
    ]);

    tl.to(text, {textShadow: '0px 0 red', duration: .05});
    tl.to(top, {duration: .05, x: 0});
    tl.to(bottom, {duration: .03, x: 0});
    //    tl.to(text, {duration: .03, scale: 1, delay: 1});

    setTl(tl);

  }, []);


  useEffect(() =>
  {
    if(tl)
    {
      if(props.animate)
      {
        tl.play();
      }
      else
      {
        tl.pause();
      }
    }
  }, [props.animate, tl]);

  return (
    <div className={'glitchText'} ref={refCont} style={{fontSize: props.fontSize}}>
      <div className={'text top'} ref={refTop}>{props.text}</div>
      <div className={'text bottom'} ref={refBottom}>{props.text}</div>
    </div>
  );
};

GlitchText.propTypes = {
  text    : PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  animate : PropTypes.bool
};

GlitchText.defaultProps = {
  fontSize: '2.5em',
  animate : true
};

export {GlitchText};
