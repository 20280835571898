class SceneEvent
{
  static SIDE_CHANGE = 'SIDE_CHANGE';


}

const SceneEventType = {
  SIDE_CHANGE  : 'SIDE_CHANGE',
  OPEN_LAYER   : 'OPEN_LAYER',
  MOVE_END     : 'MOVE_END',
  MOVE_START   : 'MOVE_START',
  SHOW_LEGEND  : 'SHOW_LEGEND',
  HIDE_LEGEND  : 'HIDE_LEGEND',
  START        : 'START',
  TRIGGER_CLICK: 'TRIGGER_CLICK',
  LAYER_VIEWED : 'LAYER_VIEWED'
};


export {SceneEvent, SceneEventType};
