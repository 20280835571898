import * as THREE from 'three';
import {AppColors} from '../comps/AppColors';


const MaterialHexaCylinderCulture = new THREE.MeshPhongMaterial({
  color      : AppColors.CULTURE,
  shininess  : 0,
  flatShading: true,
  side       : THREE.DoubleSide
});

const MaterialHexaCylinderOrga = new THREE.MeshPhongMaterial({
  color      : AppColors.ORGA,
  shininess  : 0,
  flatShading: true,
  side       : THREE.DoubleSide
});

const MaterialHexaCylinderTech = new THREE.MeshPhongMaterial({
  color      : AppColors.TECH,
  shininess  : 0,
  flatShading: true,
  side       : THREE.DoubleSide
});

const MaterialHexaCylinderLight = new THREE.MeshPhongMaterial({
  color      : '#c8c8c8',
  shininess  : 0,
  flatShading: true,
  side       : THREE.DoubleSide
});

const MaterialHexaText = new THREE.MeshBasicMaterial({
  depthWrite: true,
  depthTest : true,
  color     : 0x333333, side: THREE.DoubleSide, transparent: false, opacity: .33
});

const MaterialHexaPlate = new THREE.MeshPhongMaterial({
  color      : '#1c1c1c',
  shininess  : 0,
  flatShading: true,
  side       : THREE.DoubleSide
});
const MaterialHexaPlateActive = new THREE.MeshPhongMaterial({
  color      : '#1c1c1c',
  shininess  : 0,
  flatShading: true,
  side       : THREE.DoubleSide
});


const MaterialCylinderPillar = new THREE.MeshPhongMaterial({
  color      : '#777777',
  shininess  : 1,
  flatShading: false,
  side       : THREE.DoubleSide
});
const MaterialCylinderPillarOrange = new THREE.MeshPhongMaterial({
  color      : '#333333',
  shininess  : 1,
  flatShading: false,
  side       : THREE.DoubleSide
});

const MaterialParticle = new THREE.MeshToonMaterial({color: '#ffffff', side: THREE.DoubleSide});


export {MaterialHexaCylinderTech, MaterialHexaCylinderOrga, MaterialHexaCylinderCulture, MaterialHexaCylinderLight, MaterialHexaText, MaterialCylinderPillar, MaterialCylinderPillarOrange, MaterialParticle, MaterialHexaPlate, MaterialHexaPlateActive};
