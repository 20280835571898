const easeInOutCubic = (currentIteration, startValue, changeInValue, totalIterations) =>
{
  if((currentIteration /= totalIterations / 2) < 1)
  {
    return Math.round((changeInValue / 2 * Math.pow(currentIteration, 3) + startValue) * 1000) / 1000;
  }
  return Math.round((changeInValue / 2 * (Math.pow(currentIteration - 2, 3) + 2) + startValue) * 1000) / 1000;
};

const mathRandom = (num = 8) =>
{
  let numValue = -Math.random() * num + Math.random() * num;
  return numValue;
};


const radiansToDegree = (rad) =>
{
  return rad * 180 / Math.PI;
};

const degreeToRadians = (deg) =>
{
  return deg * Math.PI / 180;
};


const Helpers = {};
Helpers.easeInOutCubic = easeInOutCubic;
Helpers.mathRandom = mathRandom;
Helpers.radiansToDegree = radiansToDegree;
Helpers.degreeToRadians = degreeToRadians;


export {Helpers};
