import PropTypes from 'prop-types';
import React from 'react';
import {LayerCard} from './LayerCard';
import {LayerHeader} from './LayerHeader';


const LayerContent = (props) =>
{

  const renderCards = () =>
  {
    return props.pageData.layer.cards.map((item, i) => {return <LayerCard cardData={item} cluster={props.pageData.cluster} key={i} />;});
  };

  const clusterName = (props.pageData.cluster) ? ' ' + props.pageData.cluster : '';
  return (
    <div className={'content' + clusterName}>

      <div className={'pageContainer'}>
        <LayerHeader pageData={props.pageData} />
        <p className={'desc'} dangerouslySetInnerHTML={{__html: props.pageData.layer.longDesc}} />
        {renderCards()}
      </div>
    </div>
  );
};

LayerContent.propTypes = {
  pageData: PropTypes.object
};

export {LayerContent};
