import {TweenMax} from 'gsap';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {ApplicationContext} from '../App';
import {SceneEventType} from '../scene/SceneEvent';

import {ReactComponent as CloseIcon} from './../assets/closeX.svg';
import data from './../data.json';
import gsap from 'gsap';
import './layer/layer.scss';
import {LayerBackground} from './layer/LayerBackground';
import {LayerContent} from './layer/LayerContent';


const LayerContainer = (props) =>
{
  const refScrollCont = useRef();
  const [showLayerState, setShowLayerState] = useState(false);
  const [layerData, setLayerData] = useState(null);
  const refLayer = useRef(null);
  const {layerOpen, closeLayer} = useContext(ApplicationContext);

  useEffect(() =>
  {


    if(layerOpen !== null)
    {
      if(refLayer)
      {
        const backImg = refLayer.current.getElementsByClassName('layerImg'[0]);
        console.log('back', backImg);
      }





      //get layer data
      const pages = data.pages;
      let curLayerID = null;
      pages.forEach((pageData, i) =>
      {
        if(pageData.id === layerOpen)
        {
          console.log('found layer data', pageData);
          curLayerID = pageData.id;
          setLayerData(pageData);
        }
      });


      const event = new Event(SceneEventType.LAYER_VIEWED);
      event.data = {layerID: curLayerID};
      console.log('layerViewedEvent', event);

      window.dispatchEvent(event);


      setShowLayerState(true);
      gsap.to(refLayer.current, {duration: .5, ease: 'Expo.easeInOut', top: 0});
    }
    else
    {
      gsap.to(refLayer.current, {duration: .3, top: '100%'});
      gsap.set(refScrollCont.current, {scrollTo: 0, delay: .3});
    }
  }, [layerOpen]);

  const onCloseClick = () =>
  {
    //    refScrollCont.current.scrollTop = 0;

    setShowLayerState(false);
    closeLayer();
  };
  const clusterName = (layerData && layerData.cluster) ? ' ' + layerData.cluster : '';

  return (
    <div className={'layer' + clusterName} ref={refLayer}>
      {layerData !== null ? <LayerBackground pageData={layerData} /> : null}
      <div className={'scrollCont'} ref={refScrollCont}>
        {layerData !== null ? <LayerContent pageData={layerData} /> : null}
      </div>
      <div className={'layerControls'}>
        <div className={'closeBtn svgIcon'} onClick={() => onCloseClick()}><CloseIcon /></div>
      </div>
    </div>
  );
};

export {LayerContainer};
