import {useEffect, useState} from 'react';
import {SceneEventType} from '../scene/SceneEvent';


const useSlideCtrl = () =>
{
  const [currentSide, setCurrentSide] = useState('');
  const [layerOpen, setLayerOpen] = useState(null);

  const [isMoving, setIsMoving] = useState(false);

  const [showLegend, setShowLegend] = useState(false);


  useEffect(() =>
  {
    const onSideChange = (e) =>
    {
      console.log('some change', e.data.currentSide);
      const side = e.data.currentSide;
      setCurrentSide(side);
    };

    window.addEventListener(SceneEventType.SIDE_CHANGE, onSideChange);
    return () => { window.removeEventListener(SceneEventType.SIDE_CHANGE, onSideChange);};

  }, []);

  useEffect(() =>
  {
    const onShowLegend = (e) =>
    {
      setShowLegend(true);
    };

    const onHideLegend = (e) =>
    {
      setShowLegend(false);
    };

    window.addEventListener(SceneEventType.SHOW_LEGEND, onShowLegend);
    window.addEventListener(SceneEventType.HIDE_LEGEND, onHideLegend);
    return () =>
    {
      window.removeEventListener(SceneEventType.SIDE_CHANGE, onShowLegend);
      window.removeEventListener(SceneEventType.HIDE_LEGEND, onHideLegend);
    };

  }, [setShowLegend]);


  const openLayer = (side) =>
  {
    console.log('openlayer', side);

    setLayerOpen(side);
  };

  const closeLayer = () =>
  {
    setLayerOpen(null);
  };

  return {closeLayer,currentSide, setCurrentSide, openLayer, layerOpen, setLayerOpen, isMoving, setIsMoving, showLegend, setShowLegend};
};

export {useSlideCtrl};
