import {AppColors} from '../AppColors';
import data from './../../data.json';


const useClusterColor = (cluster) =>
{
  let color = '#000000';
  if(cluster)
  {

    switch (cluster)
    {
      case 'tech':
        color = AppColors.TECH;
        break;
      case 'orga':
        color = AppColors.ORGA;
        break;
      case 'culture':
        color = AppColors.CULTURE;
        break;
    }
  }
  return color;
};


const useClusterHeadline = (cluster) =>
{
  let headline = '#000000';
  if(cluster)
  {

    switch (cluster)
    {
      case 'tech':
        headline = data.text.clusterTech;
        break;
      case 'orga':
        headline = data.text.clusterOrga;
        break;
      case 'culture':
        headline = data.text.clusterCulture;
        break;
    }
  }
  return headline;
};

export {useClusterColor, useClusterHeadline};

