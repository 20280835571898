import PropTypes from 'prop-types';
import React from 'react';


const LayerBackground = (props) =>
{

  const createStyle = () =>
  {

    if(props.pageData.layer.headerImg)
    {
      return {backgroundImage: 'url(' + props.pageData.layer.headerImg + ')'};
    }
    return {};
  };

  return (
    <div className={'layerBackground'}>
      <div className={'layerImg'} style={createStyle()} />
    </div>
  );
};

LayerBackground.propTypes = {
  pageData: PropTypes.object
};

export {LayerBackground};
