import * as THREE from 'three';
import {SVGIcon} from './SVGIcon';


class ImageSlider
{
  static planeWidth = .8;
  static planeHeight = ImageSlider.planeWidth / 1.77777778;


  object = new THREE.Group();
  icons = [];

  svgIcon;

  constructor()
  {
    this.initIcons();
  }


  initIcons()
  {
    const iconList = ['car', 'truck', 'application', 'digital','bus'];

    this.iconGroup = new THREE.Group();
    const angle = 360 / iconList.length;
    const radius = .38;
    for (let i = 0; i < iconList.length; i++)
    {
      const icon = new SVGIcon(iconList[i], 'test', 'bottom', 'vertical');
      this.icons.push(icon);

      this.iconGroup.add(icon.object);

      const x = radius * Math.cos(angle * i * Math.PI / 180);
      const z = radius * Math.sin(angle * i * Math.PI / 180);
      icon.object.position.x = x;
      icon.object.position.z = z;
      icon.object.scale.multiplyScalar(.35);
    }

    const office = new SVGIcon('office', 'test', 'bottom', 'vertical');
    //    iconGroup.add(office.object);

    this.object.add(this.iconGroup);
  }



  animate(campos)
  {
    this.iconGroup.rotation.y += .002;
    this.icons.forEach(icon =>
    {
      icon.object.lookAt(campos);
    });
  }
}

export {ImageSlider};