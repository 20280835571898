import React from 'react';


const UXLabLogo = (props) =>
{
  return (
    <div className={'logo'}>
      <a href={'https://social.intra.corpintra.net/community/company/it-en/it-systems-service-and-support/lux-en'} target={'_blank'} rel="noreferrer">
        <img src={'./imgs/lux_icon_white.svg'} alt={'UXLab'} />
      </a>
    </div>
  );
};

export {UXLabLogo};
