import {gsap, TweenMax} from 'gsap';
import {MotionPathPlugin} from 'gsap/MotionPathPlugin';
import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {AppColors} from '../comps/AppColors';
import {useClusterColor} from '../comps/hooks/useClusterColor';
import {EntryScene} from './EntryScene';
import {Floor} from './Floor';
import {MaterialParticle} from './GlobalMaterials';
import {Helpers} from './Helpers';
import {HexagonGrid} from './HexagonGrid';
import {HexagonStage} from './hexagonStage/HexagonStage';
import {OrbitControls} from './OrbitControls';
import {ParticleWaves} from './ParticleWaves';
import {Pillar} from './Pillar';
import {SceneEventType} from './SceneEvent';

//MY Test
const SideColorsOld = ['#de260f', '#ff9900', '#00adef'];
const SideColors = ['#ef8804', '#777777', '#777777'];
const InitColor = '#ff00aa';


class Scene
{
  startScene = 0;
  scenes = ['entry', 'deep'];
  currentScene = this.scenes[this.startScene];

  posCamInit = {x: 0, y: 4, z: 7};
  posCamHexagon = {x: 0, y: 4, z: 4.5};

  posTargetInit = {x: 0, y: .5, z: 0};
  posTargetHexagon = {x: 0, y: .2, z: 0};

  debug = false;
  shapeClick = true;
  isAnimating = false;
  currentSide = null;
  doAnimation = false;
  doParticles = true;
  doShadows = true;
  disableFirstLayers = true;
  showFpsOnInt = false;

  backColor = '#333333';

  domEl;
  cam;
  scene;
  renderer;

  orbitTargetPos;
  orbitControls;

  floor;
  pyramid;
  hexagonGrid;
  entryScene;
  particleWaves;
  spotLight;
  smoke;

  mouse = new THREE.Vector2(0, 0);
  startMouse;
  startDeg;
  orbitStart;

  goBackTl;
  openTl;

  autoRotateCall;

  constructor() {
    gsap.registerPlugin(MotionPathPlugin);

    this.initRenderer();

    this.initFloor();
    //    this.initEntryScene();
    //    this.initHexagonGrid();

    this.initLights();
    //    this.initParticles();
    this.initSmoke();

    let hexaStage = new HexagonStage();
    this.hexagonStage = hexaStage;
    this.scene.add(hexaStage.object);


    //    this.objTest();

    const event = new Event(SceneEventType.SIDE_CHANGE);
    event.data = {currentSide: 'intro'};
    window.dispatchEvent(event);


    this.stats = new Stats();


    window.addEventListener(SceneEventType.START, () =>
    {
      this.introAnimation();
    });

    window.addEventListener(SceneEventType.TRIGGER_CLICK, (e) =>
    {
      if(e.data === 'bottom')
      {
        this.triggerHexa(null);
      }

    });

    window.addEventListener(SceneEventType.LAYER_VIEWED, (e) =>
    {

      if(this.hexagonStage)
      {
        this.hexagonStage.setLayerViewed(e.data.layerID);
      }
    });


  }

  objTest() {
    const loader = new GLTFLoader();

    loader.load('./data/untitled.glb', (gltf) =>
    {

      this.scene.add(gltf.scene);

    }, undefined, function (error)
    {

      console.error(error);

    });
  }

  initEntryScene() {
    this.entryScene = new EntryScene();
    //    this.entryScene.object.position.y = 1
    this.scene.add(this.entryScene.object);
  }


  initLights() {
    const pointLight = new THREE.PointLight(0xffffff, .6);
    pointLight.position.set(0, 2, 1);
    this.scene.add(pointLight);

    const outLight = new THREE.PointLight(0xffffff, 1);
    outLight.position.set(0, 8, 8);
    //    this.scene.add(outLight);

    //    const spotLight = new THREE.SpotLight(0xffffff, 1);
    //    spotLight.penumbra = 0;
    //    spotLight.position.set(0, 0, 2);
    //    spotLight.castShadow = true;
    //    spotLight.shadow.bias = -0.0005;
    //    spotLight.shadow.normalBias = -0.0009;
    //    spotLight.shadow.mapSize.height = 2000;
    //    spotLight.shadow.mapSize.width = 2000;

    const fixSpot = new THREE.SpotLight(0xffffff, .6);
    fixSpot.penumbra = 0;
    fixSpot.position.set(0, 4, 4.5);
    this.fixSpot = fixSpot;
    if(this.doShadows)
    {

      fixSpot.castShadow = true;
      fixSpot.shadow.bias = -0.005;
      fixSpot.shadow.mapSize.height = 512 * 9;
      fixSpot.shadow.mapSize.width = 512 * 9;
      fixSpot.distance = 1000;
      fixSpot.shadow.camera.near = 1;
      fixSpot.shadow.camera.far = 20;
      fixSpot.shadow.camera.fov = 45;
    }

    const wanderLight = new THREE.SpotLight(0xffffff, .8);
    wanderLight.penumbra = 0;
    wanderLight.position.set(0, 2, 4.5);


    //    spotLight.shadow.camera.near = 1;  // same as the camera
    //    spotLight.shadow.camera.far = 90;  // same as the camera
    //    spotLight.shadow.camera.top = 10;
    //    spotLight.shadow.camera.bottom = -10;
    //    spotLight.shadow.camera.left = 10;
    //    spotLight.shadow.camera.right = -10;

    this.scene.add(fixSpot);
    //    this.scene.add(wanderLight);
    this.spotLight = wanderLight;

    //    this.scene.add(spotLightHelper);

  }


  initSmoke() {
    this.smoke = new THREE.Object3D();

    const material = MaterialParticle;
    const gparticular = new THREE.CircleBufferGeometry(.005, 2);
    const aparticular = 5;

    for (let h = 1; h < 400; h++)
    {
      const particular = new THREE.Mesh(gparticular, material);
      particular.position.set(Helpers.mathRandom(aparticular), Helpers.mathRandom(aparticular), Helpers.mathRandom(aparticular));
      particular.rotation.set(Helpers.mathRandom(), Helpers.mathRandom(), Helpers.mathRandom());
      this.smoke.add(particular);
    }

    this.scene.add(this.smoke);

    const pmaterial = new THREE.MeshPhongMaterial({
      color: 0x000000,
      side : THREE.DoubleSide,
      //      roughness  : 1,
      //      metalness  : 0.2,
      opacity    : 0.3,
      transparent: true
    });
    let pgeometry = new THREE.PlaneBufferGeometry(60, 60);
    let pelement = new THREE.Mesh(pgeometry, pmaterial);
    pelement.rotation.x = -90 * Math.PI / 180;
    pelement.position.y = -0.001;
    pelement.receiveShadow = false;

    //    this.scene.add(pelement);

  }

  startAutoRotate(controls) {
    console.log('start auto Rotate---------');
    controls.autoRotate = true;
  }

  startAutoRotateTimer() {
    this.orbitControls.autoRotate = false;
    if(this.autoRotateCall)
    {
      this.autoRotateCall.kill();
    }
    if(this.currentScene === this.scenes[0])
    {
      this.autoRotateCall = gsap.delayedCall(1, this.startAutoRotate, [this.orbitControls]);
    }
  }

  stopAutoRotate() {
    if(this.autoRotateCall)
    {
      this.autoRotateCall.kill();
    }
    this.orbitControls.autoRotate = false;
  }

  initOrbitControls() {
    const initCamPos = this.posCamInit;
    this.cam.position.set(initCamPos.x, initCamPos.y + 5, initCamPos.z + 20);
    const controls = new OrbitControls(this.cam, this.renderer.domElement);

    const targetPos = this.posTargetInit;
    controls.target = new THREE.Vector3(targetPos.x, targetPos.y, targetPos.z);
    controls.screenSpacePanning = true;
    controls.enableDamping = true;
    //    controls.autoRotate = true;
    controls.autoRotateSpeed = 1;

    if(!this.debug)
    {
      controls.enableZoom = false;
      controls.enablePan = false;
      //      controls.maxPolarAngle = Math.PI * Math.PI / 180;
      controls.maxPolarAngle = 1.4;
      controls.minPolarAngle = 0.8;
      //      controls.maxDistance = 8;
      controls.maxDistance = 20;
      controls.minDistance = 2.5;
    }

    controls.update();

    controls.addEventListener('change', (e) => this.onOrbitControlsChange(e));

    window.addEventListener('mousemove', e => this.onMouseMove(e), true);
    window.addEventListener('mousedown', e => this.onMouseDown(e), true);
    window.addEventListener('touchmove', e => this.onTouchMove(e), true);
    window.addEventListener('touchstart', e => this.onTouchstart(e), true);
    controls.addEventListener('start', (e) => this.onOrbitStart(e));
    controls.addEventListener('end', (e) => this.onOrbitEnd(e));

    this.orbitControls = controls;
    //    this.startAutoRotateTimer();
  }


  initRenderer() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);
    if(!this.debug)
    {
      const near = 4;
      const far = 7;
      //            this.scene.fog = new THREE.Fog(this.backColor, near, far);
    }

    const camPos = this.posCamInit;
    this.cam = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 50);
    //    this.cam.position.set(camPos.x, camPos.y, camPos.z);
    this.cam.position.set(camPos.x, camPos.y, 10);

    let pixelRatio = window.devicePixelRatio;
    let AA = true;
    if(pixelRatio > 1)
    {
      AA = false;
    }

    this.renderer = new THREE.WebGLRenderer({antialias: AA, powerPreference: 'high-performance'});
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    if(this.doShadows)
    {
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      this.renderer.shadowMap.needsUpdate = false;
    }

    //    this.renderer.gammaFactor = 1.5;
    //    this.renderer.gammaOutput = true;

    this.raycaster = new THREE.Raycaster();
  }

  initFloor() {
    const floor = new Floor(AppColors.FLOOR);
    floor.object.position.y = 0;
    this.scene.add(floor.object);

    this.floor = floor;
  }


  initHexagonGrid() {
    const hexagonGrid = new HexagonGrid();
    const object = hexagonGrid.object;

    this.hexagonGrid = hexagonGrid;
    this.hexagonGrid.object.position.y = Pillar.cylinderHeight / 2;


    const scale = .2;
    //    const scale = .8;
    this.hexagonGrid.object.scale.x = scale;
    this.hexagonGrid.object.scale.y = scale;
    this.hexagonGrid.object.scale.z = scale;
    this.scene.add(object);
  }

  initParticles() {
    this.particleWaves = new ParticleWaves();
    this.scene.add(this.particleWaves.pGroup);
  }

  startAnimation() {
    if(!this.isAnimating)
    {
      this.isAnimating = true;
      gsap.ticker.add(() => this.animate());
    }
  }


  animate() {
    if(this.showFps)
    {
      this.stats.update();
    }


    if(this.doAnimation === false)
    {
      //      console.log('noAnimation');
      return;
    }


    if(this.hexagonStage)
    {
      this.hexagonStage.renderTick(this.cam.position.clone(), this.orbitControls.getAzimuthalAngle());
    }

    //    requestAnimationFrame(() => this.animate());
    this.orbitControls.update();


    if(this.floor)
    {
      //      this.floor.animate();
    }

    if(this.hexagonGrid)
    {
      if(this.currentScene === this.scenes[1])
      {
        this.hexagonGrid.renderTick(this.cam.position.clone(), this.orbitControls.getAzimuthalAngle());
      }
    }

    if(this.entryScene)
    {
      //      if(this.currentScene === this.scenes[0])
      //      {
      this.entryScene.animate(this.cam.position.clone(), this.orbitControls.getAzimuthalAngle());
      //      }
    }

    if(this.spotLight)
    {
      this.spotLight.position.x = this.cam.position.x;
      this.spotLight.position.z = this.cam.position.z;

      //      this.fixSpot.position.x = this.cam.position.x * 1.5;
      //      this.fixSpot.position.z = this.cam.position.z;
      //      this.spotLight.position.y = this.cam.position.y * 2;
    }


    if(this.doParticles && this.particleWaves)
    {
      this.particleWaves.animate();
    }

    if(this.smoke)
    {
      this.smoke.rotation.y += 0.0005;
      this.smoke.rotation.x += 0.0005;
    }

    this.renderer.render(this.scene, this.cam);
  }

  setDomEl(domEl) {
    console.log('setDomEl', domEl);
    this.domEl = domEl;
    domEl.appendChild(this.renderer.domElement);

    this.domEl = domEl;

    if(this.showFpsOnInt)
    {
      this.showFps = true;
      domEl.appendChild(this.stats.dom);
    }


    window.addEventListener('resize', (e) => this.onWindowResize(e));
    window.addEventListener('keydown', (e) =>
    {
      if(e.key === 'f')
      {
        if(this.showFps === false)
        {

          this.showFps = true;
          domEl.appendChild(this.stats.dom);
        }
        else
        {
          this.showFps = false;
          this.stats.dom.remove();
        }
      }
      else if(e.key === 'm')
      {

        this.doParticles = !this.doParticles;
        console.log('toggle Particles', this.doParticles);
      }
      else if(e.key === 'l')
      {
        this.toggleParticles();
      }
      else if(e.key === 'c')
      {
        console.log(this.orbitControls.getAzimuthalAngle());
        console.log('atzToDeg', this.atzToDeg());
      }
      else if(e.key === 'a')
      {
        this.orbitControls.testSome(Helpers.degreeToRadians(72));
      }
      else if(e.key === 'r')
      {
        //        this.orbitControls.rotateLeft(2);
        //        this.orbitControls.update();
      }
      else
      {
        this.handleKeyShortcut(e.key);
      }
    });


    this.initOrbitControls();
    this.startAnimation();
  }

  toggleParticles() {
    if(this.particleWaves)
    {
      console.log('removeParticles');
      this.scene.remove(this.particleWaves.pGroup);
      this.particleWaves.dispose();
      this.particleWaves = null;
      this.renderer.clear(true, true, true);
    }
    else
    {
      console.log('initParticles');
      this.initParticles();
    }


  }


  onOrbitControlsChange(e) {
    if(this.pyramid)
    {
      this.pyramid.onOrbitControlsChange(e);
    }
  }


  onOrbitStart(e) {
    this.startMouse = this.mouse.clone();

    this.stopAutoRotate();


    this.startDeg = Math.floor(this.orbitControls.getAzimuthalAngle() * (180 / Math.PI) + 180);


    let sEvent = new Event(SceneEventType.MOVE_START);
    sEvent.data = {side: this.currentSide};
    window.dispatchEvent(sEvent);

    //    console.log('orbitStart', this.startMouse, this.mouse);
  }

  onOrbitEnd(e) {
    //    console.log('orbitEnd');
    const endDeg = Math.floor(this.orbitControls.getAzimuthalAngle() * (180 / Math.PI) + 180);
    let degDif = (this.startDeg - endDeg);
    let degDifAbs = Math.abs(degDif);

    if(degDifAbs <= 1 && this.shapeClick)
    {
      let checkPos = new THREE.Vector2();
      checkPos.x = (this.mouse.x / window.innerWidth) * 2 - 1;
      checkPos.y = -(this.mouse.y / window.innerHeight) * 2 + 1;
      //      console.log('check', checkPos);

      this.raycaster.setFromCamera(checkPos, this.cam);

      //      if(this.entryScene || this.hexagonGrid)
      //      {
      //        const intersectObjs = (this.currentScene === this.scenes[0]) ? this.entryScene.object : this.hexagonGrid.object;
      const intersectObjs = this.hexagonStage.object;
      let intersects = this.raycaster.intersectObject(intersectObjs, true);

      if(intersects.length > 0)
      {
        this.handleClick(intersects);

      }
      //      }
    }


    let sEvent = new Event(SceneEventType.MOVE_END);
    sEvent.data = {side: this.currentSide};
    window.dispatchEvent(sEvent);


    this.startAutoRotateTimer();

    //    let posRotZ = (degDif * Math.PI / 180) + this.orbitControls.dampingFactor;
    //    TweenMax.to(this.pyramid.textPlane.rotation, .4, {z: posRotZ, delay: 0, ease: 'Bounce.easeOut'});
  }


  handleClick(intersects) {
    let name = intersects[0].object.name;
    console.log('handleClick', name);
    const event = new Event(SceneEventType.SIDE_CHANGE);
    let sideName = name;
    event.data = {currentSide: sideName};

    let activeHexagon = this.hexagonStage.activate(name);

    if(activeHexagon)
    {
      const test = new THREE.Vector3();
      activeHexagon.object.getWorldPosition(test);
      console.log(test);

      gsap.to(this.orbitControls.target, {duration: 2.5, z: test.z, x: test.x, ease: 'Cubic.easeOut'});

      const color = activeHexagon.getClusterColor();
      this.floor.changeGroundColorTween(color, 1);

    }


    window.dispatchEvent(event);


    return;
    //OLD


    const camPos = this.posCamInit;
    let floorColor;

    const tl = gsap.timeline({callbackScope: this, onComplete: (name !== 'back') ? this.enableRotate : null});
    this.openTl = tl;

    this.stopAutoRotate();

    if(this.currentScene === this.scenes[0])
    {

      if(this.disableFirstLayers)
      {
        name = 'bottom';
      }

      event.data = {currentSide: name};
      //Entry Scene
      switch (name)
      {
        case 'layer1':
          camPos.y = 2.2;
          floorColor = SideColors[2];
          window.dispatchEvent(new Event(SceneEventType.HIDE_LEGEND));
          break;
        case 'layer2':
          camPos.y = 1.4;
          floorColor = SideColors[1];
          window.dispatchEvent(new Event(SceneEventType.HIDE_LEGEND));
          break;
        case 'bottom':
          camPos.y = 1.2;
          floorColor = SideColors[0];
          event.data = {currentSide: null};
          window.dispatchEvent(new Event(SceneEventType.SHOW_LEGEND));
          break;
        default:
          return;
      }

      if(name !== 'bottom')
      {
        console.log('camTween');
        tl.add([
          gsap.to(this.cam.position, {duration: .6, y: camPos.y})
        ]);
        tl.add(this.entryScene.activate(name), 0);
      }


      tl.add('start');

      tl.add([
        this.floor.changeGroundColorTween(floorColor, 2)
      ], 0);


      if(name === 'bottom')
      {
        this.currentScene = this.scenes[1];
        const scaleHexa = .6;

        gsap.killTweensOf([this.orbitControls.target, this.hexagonGrid.object.scale, this.hexagonGrid.goBackTile.object.scale]);
        gsap.killTweensOf(this.enableRotate);

        this.disableRotate();
        //Animate Cam to see Pillars
        tl.add([
          gsap.to(this.cam.position, {duration: 1, z: 6, y: .5}),
          gsap.to(this.orbitControls.target, {duration: 1, y: 1})
        ], 'start');

        //Hide Pillars
        tl.add([
          this.entryScene.hidePillarsAni()
        ], 'start+=-.2');

        //Scale Hexa
        tl.add(gsap.to(this.hexagonGrid.object.scale, {
          x: scaleHexa, y: scaleHexa, z: scaleHexa, duration: .5
        }), 'start+=.7');

        tl.add('scale');

        tl.add([
          this.hexagonGrid.showGridAni(),
          gsap.to(this.hexagonGrid.goBackTile.object.scale, {x: .8, y: .8, z: .8, duration: .3})
        ], 'scale-=.3');

        tl.add([
          gsap.to(this.cam.position, {duration: 1, z: 4.5, y: 3}),
          gsap.to(this.orbitControls.target, {duration: 1, y: .4})
        ], 'scale-=.7');
      }

      window.dispatchEvent(event);
    }
    else if(this.currentScene === this.scenes[1])
    {
      let sideName;
      //Hexagon Scene
      if(name !== 'back')
      {
        sideName = 'hex' + name;
        const hexagon = this.hexagonGrid.tiles[name];
        event.data = {currentSide: (!hexagon.isActive) ? sideName : null};
        //        this.orbitControls.testSome(Helpers.degreeToRadians(hexagon.angle) - this.orbitControls.getAzimuthalAngle());

        const hexAngle = hexagon.angle - 180;
        const atzToDeg = this.atzToDeg();
        console.log('atzToDeg', atzToDeg);
        console.log('hex', hexAngle);

        let div = hexAngle + atzToDeg;
        if(div > 180)
        {
          div -= 360;
        }


        console.log('div', div);

        this.orbitControls.testSome(Helpers.degreeToRadians(div));


        //        this.orbitControls.testSome(Helpers.degreeToRadians(div / 2));


      }
      else if(name === 'back')
      {
        event.data = {currentSide: 'intro'};
        this.goBack();
      }

      this.hexagonGrid.activate(name);
      window.dispatchEvent(event);

    }
  }


  atzToDeg() {
    return Helpers.radiansToDegree(this.orbitControls.getAzimuthalAngle()) + 180;
  }


  goBack() {
    console.log('goBackAni');
    this.currentScene = this.scenes[0];

    this.disableRotate();

    window.dispatchEvent(new Event(SceneEventType.HIDE_LEGEND));

    gsap.killTweensOf([this.cam.position, this.orbitControls.target, this.hexagonGrid.object.scale, this.hexagonGrid.goBackTile.object.scale]);
    gsap.killTweensOf(this.enableRotate);

    const campPos = this.posCamInit;
    const targetPos = this.posTargetInit;


    let tl = gsap.timeline({callbackScope: this, onComplete: this.enableRotate});
    //    let tl = gsap.timeline({callbackScope: this});
    this.goBackTl = tl;


    tl.add(gsap.to(this.cam.position, {duration: 1.5, z: campPos.z, y: 2.5}), 0);
    //    tl.add(gsap.to(this.cam.position, {duration: .5, x: campPos.x, y: campPos.y, z: campPos.z}), 0);
    tl.add(gsap.to(this.orbitControls.target, {duration: .5, x: targetPos.x, y: targetPos.y, z: targetPos.z}), 0);
    tl.add(this.hexagonGrid.hideGridAni(), 0);
    tl.add(gsap.to(this.hexagonGrid.object.scale, {
      x       : .2,
      y       : .2,
      z       : .2,
      duration: .3
    }), .2);
    tl.add(this.entryScene.showPillarsAni(), .4);
    tl.add(this.entryScene.activate(''), .2);
    tl.add([
      this.floor.changeGroundColorTween(InitColor, 1)
    ], 0);
    tl.add([
      gsap.to(this.hexagonGrid.goBackTile.object.scale, {x: .01, y: .01, z: .01, duration: .3})
    ], 0);
  }


  handleKeyShortcut(key) {
    switch (key)
    {
      case '0':
        //        this.triggerLayer(null);
        break;
      case '1':
      case 'Escape':
        this.triggerLayer(null);
        break;
      case '2':
        this.triggerHexa(null);
        break;
      case '3':
        this.triggerHexa(2);
        break;
      case '4':
        this.triggerHexa(3);
        break;
      case '5':
        this.triggerHexa(4);
        break;
    }
  }

  triggerLayer(layerNumber) {
    if(this.currentScene === this.scenes[1])
    {
      if(!layerNumber)
      {


        this.hexagonGrid.activate('');
        this.goBack();
        if(this.openTl)
        {
          this.openTl.kill();
        }

        const event = new Event(SceneEventType.SIDE_CHANGE);
        event.data = {currentSide: 'intro'};
        window.dispatchEvent(event);
      }
      else
      {
        const layer = this.entryScene['layer' + layerNumber];
        this.handleClick([layer]);
      }

    }
    //    this.currentScene = this.scenes[0];
    //
    //    if(layerNumber)
    //    {
    //      const layer = this.entryScene['layer' + layerNumber];
    //      this.handleClick([layer]);
    //    }
    //    else
    //    {
    //      this.goBack();
    //    }

    //    this.goBack();
  }

  triggerHexa(hexID) {
    if(this.openTl)
    {
      //      this.openTl.kill();

    }
    if(this.goBackTl)
    {
      this.goBackTl.kill();
    }

    let delay = .01;
    if(this.currentScene === this.scenes[0])
    {
      this.handleClick([this.entryScene.omPillar]);
      delay = 3.5;
    }
    else
    {
      const event = new Event(SceneEventType.SIDE_CHANGE);
      event.data = {currentSide: null};
      console.log('trigger null slide');
      window.dispatchEvent(event);
      //      this.hexagonGrid.activate('');
    }
    if(this.triggerCall)
    {
      this.triggerCall.kill();
    }
    if(hexID)
    {
      this.triggerCall = gsap.delayedCall(delay, (hexID) => this.triggerHexaCallback(hexID), [hexID]);
    }
  }


  introAnimation() {
    this.doAnimation = true;
    if(this.entryScene)
    {
      this.entryScene.setPillarOut();
    }

    const tl = gsap.timeline({delay: .5});
    tl.add([
      gsap.to(this.cam.position, {duration: 2.5, z: this.posCamInit.z, y: this.posCamInit.y, ease: 'Cubic.easeOut'})
    ]);


    //    tl.add([
    //      this.entryScene.layer2.tweenToInitPos(.8)
    //    ], .5);
    //    tl.add([
    //      this.entryScene.layer1.tweenToInitPos(.8)
    //    ], .8);

    tl.add(() =>
    {
      const event = new Event(SceneEventType.SIDE_CHANGE);
      event.data = {currentSide: 'intro'};
      window.dispatchEvent(event);
    }, .5);

    tl.add(() => this.startAutoRotateTimer());
  }

  triggerHexaCallback(hexID) {
    const hexagon = this.hexagonGrid.tiles[hexID];
    this.handleClick([hexagon]);
  }

  showLayer() {
    let event = new Event(SceneEventType.OPEN_LAYER);
    //    event.data = {side: name};
    //    window.dispatchEvent(event);

    //    TweenMax.to(this.pyramid.object.position, .3, {y: -3});
    TweenMax.to(this.cam.position, .3, {y: -3, z: -4});

  }

  setMovePos(pos) {
    this.mouse.x = pos.clientX;
    this.mouse.y = pos.clientY;

    //    const debug = document.getElementById('debug');
    //    debug.innerText = pos.clientX + '/' + pos.clientY;
  }

  onMouseMove(e) {
    this.setMovePos(e);

    if(this.hexagonGrid)
    {

      let intersects = this.raycaster.intersectObject(this.hexagonGrid.object, true);
      if(intersects.length > 0)
      {
        const name = intersects[0].object.name;
      }
    }


  }

  onTouchMove(e) {
    this.setMovePos(e.touches[0]);
  }

  onTouchstart(e) {
    this.setMovePos(e.touches[0]);
    this.startMouse = this.mouse.clone();
  }

  onMouseDown(e) {
    this.setMovePos(e);
    this.startMouse = this.mouse.clone();
  }

  onWindowResize(e) {
    let winWith = window.innerWidth;
    let winHeight = window.innerHeight;

    this.renderer.setSize(winWith, winHeight);
    this.cam.aspect = winWith / winHeight;
    this.cam.updateProjectionMatrix();
  }

  enableCtrl() {

    this.doAnimation = true;
    this.orbitControls.enableRotate = true;
    this.animate();
  }

  disableCtrl() {
    this.doAnimation = false;
    this.orbitControls.enableRotate = false;
  }

  disableRotate() {
    console.log('deactivate rotate');
    this.orbitControls.enableRotate = false;
  }

  enableRotate() {
    console.log('activate rotate');
    this.orbitControls.enableRotate = true;
  }
}

export {Scene, SideColors};
