import {gsap, TweenLite, TweenMax} from 'gsap';
import * as THREE from 'three';
import {CheckerNode} from 'three/examples/jsm/nodes/procedural/CheckerNode';
import {MaterialHexaCylinderCulture, MaterialHexaCylinderOrga, MaterialHexaCylinderTech, MaterialHexaText} from './GlobalMaterials';
import {CheckMaterial, SVGIcon} from './SVGIcon';


class HexagonTile
{
  id;
  svgURL;
  textSVGUrl;
  isActive;
  angle;
  color;

  plate;
  cylinder;
  svgIcon;
  viewedIcon; //show if already viewed
  label = null;

  colorTween;


  object; //The Group


  constructor(id, svgURL, textSVGUrl, color = 0x333333, angle = 0, svgColor = '#ffffff') {
    this.svgColor = svgColor;
    this.angle = angle;
    this.color = color;
    this.id = id;
    this.svgURL = svgURL;
    this.textSVGUrl = textSVGUrl;
    this.object = new THREE.Group();
    this.object.name = id;

    this.drawPlate();
    this.drawCylinder();
    this.drawIcon();
    this.drawViewedIcon();

  }


  drawPlate() {
    const geometry = new THREE.CylinderBufferGeometry(HexagonTile.size, HexagonTile.size, .03, 6, 1, false);

    const material = new THREE.MeshPhongMaterial({
      color      : '#1c1c1c',
      shininess  : 0,
      flatShading: true,
      side       : THREE.DoubleSide
    });

    this.plate = new THREE.Mesh(geometry, material);
    this.plate.name = this.id;
    this.plate.rotation.y = Math.PI / 360 * 60;
    this.object.add(this.plate);
  }

  drawCylinder() {
    const geometry = new THREE.CylinderBufferGeometry(.5, .5, .05, 6);
    //    const material = new THREE.MeshLambertMaterial({
    //      color      : this.color,
    //      shininess  : 0,
    //      flatShading: true,
    //      side       : THREE.DoubleSide
    //    });

    let material;
    switch (this.color)
    {
      case 'culture':
        material = MaterialHexaCylinderCulture;
        break;
      case 'orga':
        material = MaterialHexaCylinderOrga;
        break;
      case 'tech':
        material = MaterialHexaCylinderTech;
        break;
    }


    this.cylinder = new THREE.Mesh(geometry, material);
    this.cylinder.castShadow = true;
    this.cylinder.receiveShadow = true;

    this.cylinder.name = this.id;
    this.cylinder.rotation.y = Math.PI / 360 * 60;
    this.object.add(this.cylinder);
  }

  drawIcon() {
    //    const icon              = new SVGIcon('checked', this.id, 'center', 'horizontal', this.svgColor);
    const icon = new SVGIcon(this.svgURL, this.id, 'center', 'horizontal', this.svgColor);
    this.svgIcon = icon.object;
    this.svgIcon.position.y = .05;

    this.object.add(this.svgIcon);
  }


  drawViewedIcon() {
    const icon = new SVGIcon('checked', this.id, 'center', 'horizontal', '#00ff00', null, CheckMaterial);
    this.viewedIcon = icon.object;
    this.viewedIcon.position.y = .06;
    this.viewedIcon.position.x = .2;
    this.viewedIcon.position.z = .2;


  }

  drawText() {
    const icon = new SVGIcon(this.textSVGUrl, this.id);
    this.svgText = icon.object;
    this.svgText.scale.multiplyScalar(.35);
    //    this.svgText.position.y = .01;
    //    this.svgText.position.z = .75;

    this.svgText.position.y = .02;
    let texture = new THREE.TextureLoader().load('./icons/' + this.textSVGUrl + '.png');
    const geometry = new THREE.PlaneBufferGeometry(1.5, .4);

    const plane = new THREE.Mesh(geometry, MaterialHexaText);
    plane.name = this.id;
    plane.rotation.x = Math.PI / 2;

    const label = new THREE.Group();
    label.add(plane);
    label.add(this.svgText);

    label.scale.multiplyScalar(.8);
    label.name = this.id;

    label.position.y = .2;
    label.position.z = .65;

    label.rotation.x = Math.PI / 3.5;

    const lookGroup = new THREE.Group();
    lookGroup.name = this.id;
    lookGroup.add(label);

    this.label = lookGroup;
    lookGroup.scale.multiplyScalar(.01);


    this.object.add(lookGroup);
  }

  testSome(mode) {
    console.log('activate rotation for', this.id, this);
    this.iconRotation = mode;
  }


  setActiveMat() {
    //    console.log('setActiveMat',this.id);
    //    this.plate.material = MaterialHexaPlateActive;
  }

  removeActiveMat() {
    //    console.log('removeActiveMat',this.id);
    //    this.plate.material = MaterialHexaPlate;
  }

  showAnimation() {
    gsap.killTweensOf([this.colorTween, this.plate.scale, this.svgIcon.position, this.cylinder.scale], false);
    gsap.killTweensOf(this.testSome);
    gsap.killTweensOf(this.removeActiveMat);
    if(!this.isActive)
    {
      this.setActiveMat();

      this.isActive = true;

      const self = this;

      const tl = gsap.timeline({
        callbackScope   : this,
        onComplete      : this.testSome,
        onCompleteParams: [true]
      });

      this.showTl = tl;

      this.tweenColor(this.plate, '#ffffff', this.colorTween);

      tl.add([
        gsap.to(this.plate.scale, {duration: .3, y: 10, yoyo: true, repeat: 1}),
        gsap.to(this.svgIcon.position, {duration: .3, y: 0}),
        gsap.to(this.viewedIcon.position, {duration: .3, y: 0})
      ]);

      tl.add([
        gsap.to(this.svgIcon.rotation, {duration: .3, x: Math.PI / 2})
      ], .2);


      tl.add([
        gsap.to(this.cylinder.scale, {duration: .3, y: 40}),
        gsap.to(this.svgIcon.position, {duration: .3, y: 1.4}),
        gsap.to(this.viewedIcon.position, {duration: .3, y: 1.05})
      ]);

      tl.add([
        gsap.to(this.svgIcon.rotation, {duration: .3, x: Math.PI / 2})
      ], .2);


    }
  }

  hideAnimation() {
    this.isActive = false;
    TweenMax.killTweensOf([this.colorTween, this.plate.scale, this.svgIcon.position, this.cylinder.scale]);
    gsap.killTweensOf(this.testSome);
    gsap.killTweensOf(this.removeActiveMat);
    this.testSome(false);

    if(this.showTl)
    {
      this.showTl.kill();
    }

    const tl = gsap.timeline({callbackScope: this, onComplete: this.removeActiveMat});
    tl.add([
      TweenMax.to(this.plate.scale, .3, {y: 41, yoyo: true, repeat: 1})
    ]);

    tl.add([
      TweenMax.to(this.svgIcon.position, .2, {y: 0.05}),
      TweenMax.to(this.viewedIcon.position, .2, {y: 0.05}),
      TweenMax.to(this.svgIcon.rotation, .2, {delay: .2, x: 0, z: 0})
    ], .15);

    tl.add([
      TweenMax.to(this.cylinder.scale, .3, {y: 1}),
      this.tweenColor(this.plate, '#1c1c1c', this.colorTween)
    ], .2);

    tl.add([
      TweenMax.to(this.plate.scale, .3, {y: 1})
    ]);


  }


  tweenColor(target, color, tween) {
    const curColor = target.material.color;
    const newColor = new THREE.Color(color);

    if(curColor.getHexString() !== newColor.getHexString())
    {

      if(tween)
      {
        tween.kill();
      }

      tween = TweenLite.to(curColor, .3,
        {
          r       : newColor.r,
          g       : newColor.g,
          b       : newColor.b,
          onUpdate: () =>
          {
            target.material.color = curColor;
            target.material.needsUpdate = true;
          }
        });

    }

    return tween;
  }

  animate(campos) {
    //    this.label.lookAt(campos.clone());
    //    this.svgText.lookAt(campos.clone());
  }

  setViewed(){
    this.object.add(this.viewedIcon);
  }

}

HexagonTile.size = .55;

export {HexagonTile};
