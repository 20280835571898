import {gsap} from 'gsap';
import * as THREE from 'three';
import {Vector3} from 'three';
import {ImageSlider} from './ImageSlider';
import {Pillar} from './Pillar';


class EntryScene
{
  object = new THREE.Group();


  omPillar;
  layer2;
  layer1;


  constructor() {
    this.createPillars();
    //    this.createImageSlider();
  }

  createImageSlider() {
    this.imageSlider = new ImageSlider();
    this.imageSlider.object.position.y = Pillar.cylinderHeight / 2 + .05;
    this.layer1.object.add(this.imageSlider.object);
  }

  createPillars() {
    //bottom
    this.omPillar = new Pillar('bottom', null, .8, 'orange',
      false, null, true, null, false);
    this.omPillar.setInitPos(new Vector3(0, 0));
    this.object.add(this.omPillar.object);

    //layer2
    //    this.layer2 = new Pillar('layer2', 'text_int_aps', .7, 'dark', true, 'noun_smart_3394309');
    //    this.layer2.setInitPos(new Vector3(0, 4 * Pillar.cylinderHeight));
    //    this.object.add(this.layer2.object);

    //layer1
    //    this.layer1 = new Pillar('layer1', 'text_DP', .8, SideColors[2], true);
    //    this.layer1 = new Pillar('layer1', 'text_DP', .6, 'dark',
    //      true, 'noun_digital_2995235', false, '#777777', false);
    //    this.layer1.setInitPos(new Vector3(0, 8 * Pillar.cylinderHeight));
    //    this.object.add(this.layer1.object);
  }


  activate(id) {
    //    const pillars = [this.layer2, this.layer1, this.omPillar];
    const pillars = [];

    const showPillarAni = gsap.timeline();

    pillars.forEach((pillar) =>
    {
      if(pillar.name === id)
      {
        showPillarAni.add(pillar.showAnimation(), 0);
      }
      else
      {
        showPillarAni.add(pillar.hideAnimation(), 0);
      }
    });

    const tl = gsap.timeline();
    tl.add(showPillarAni);

    switch (id)
    {
      default:
      case 'layer1':
//        tl.add([
//          this.layer2.tweenToInitPos()
//        ], 0);
//        tl.add([
//          this.layer1.tweenToInitPos()
//        ], .1);
        break;
      case 'layer2':
        tl.add([
          gsap.to(this.layer1.object.position, {duration: .3, y: Pillar.cylinderHeight * 12}),
          this.layer2.tweenToInitPos()
        ], 0);
        break;
      case 'bottom':
        tl.add([
          gsap.to(this.layer1.object.position, {duration: .3, y: Pillar.cylinderHeight * 14}),
          gsap.to(this.layer2.object.position, {duration: .3, y: Pillar.cylinderHeight * 12})
        ], 0);
        break;
    }

    return tl;


  }

  hidePillarsAni() {
    const tl = gsap.timeline();

    //    tl.add([
    //      gsap.to(this.layer1.object.position, {duration: .3, y: Pillar.cylinderHeight * 14}),
    //      gsap.to(this.layer2.object.position, {duration: .3, y: Pillar.cylinderHeight * 10, delay: .2})
    //    ], 0);
    //
    //    tl.add('moveOut');
    //    tl.add(gsap.to(this.layer1.object.position, {duration: .8, y: 6}));
    //    tl.add(gsap.to(this.layer2.object.position, {duration: .8, y: 6}), '-=.3');
    //
    //    tl.add([
    //      gsap.to(this.omPillar.object.scale, {duration: .3, x: scale, z: scale})
    //    ], .5);

    const scale = 2.4;
    //    tl.add([
    //      gsap.to(this.layer1.object.position, {duration: .5, y: Pillar.cylinderHeight * 16}),
    //      gsap.to(this.layer2.object.position, {duration: .5, y: Pillar.cylinderHeight * 12, delay: .2})
    //    ]);

    tl.add([
      gsap.to(this.omPillar.object.scale, {duration: .5, x: scale, z: scale}),
      this.omPillar.showAnimation()

    ], .7);

    //    tl.add([
    //      gsap.to(this.layer1.object.position, {duration: .8, y: 4}),
    //      gsap.to(this.layer2.object.position, {duration: .8, y: 4, delay: .2})
    //    ], .7);

    return tl;
  }

  showPillarsAni() {
    const tl = gsap.timeline();

    const scale = 1;
    tl.add([
      gsap.to(this.omPillar.object.scale, {duration: .5, x: scale, z: scale})
    ]);


    return tl;
  }


  animate(campos, angle) {
    //    this.layer1.animate(angle);
    //    this.layer2.animate(angle);
    this.omPillar.animate(angle);
    //    this.imageSlider.animate(campos);
  }

  getActivePillar(id) {
    const pillars = [this.layer2, this.layer1];

    let result = null;

    pillars.forEach((pillar) =>
    {
      if(pillar.name === id)
      {
        console.log('getPillar', pillar);
        result = pillar;
      }
    });
    return result;
  }

  onOrbitEnd() {

  }


  setPillarOut() {
    //    this.layer1.object.position.y = 10;
    //    this.layer2.object.position.y = 10;
    //    this.omPillar.object.position.y = 10;
  }


}

export {EntryScene};
