import {gsap} from 'gsap';
import * as THREE from 'three';
import {AppColors} from '../../comps/AppColors';
import {useClusterColor} from '../../comps/hooks/useClusterColor';
import {HexagonTile} from '../HexagonTile';
import {getRandomArbitrary} from '../MyUtils';


class BigHexagon
{
  static SIZE = .8;
  static HEIGHT = .2;

  cluster;
  id;
  color;
  isActive = false;

  pillar;

  object = new THREE.Group();


  moveAni;

  constructor(color = '#151515', pageData) {
    this.id = pageData.id;
    this.cluster = pageData.cluster;
    this.iconURL = pageData.icon;
    this.color = color;
    this.createHexagon();
    this.createPillar();
    this.animate();
  }


  createHexagon() {
    const mat = new THREE.MeshPhongMaterial({
      color      : this.color,
      shininess  : 1,
      flatShading: true,
      side       : THREE.DoubleSide
    });

    const geoCylinder = new THREE.CylinderBufferGeometry(BigHexagon.SIZE, BigHexagon.SIZE, .2, 6, 1, false);
    let cylinder = new THREE.Mesh(geoCylinder, mat);
    cylinder.castShadow = true;
    cylinder.receiveShadow = true;

    cylinder.name = this.id;
    this.object.name = this.id;

    this.object.add(cylinder);
  }

  createPillar() {


    this.pillar = new HexagonTile(this.id, this.iconURL, 'tile_CS', this.cluster);
    this.pillar.object.position.y = BigHexagon.HEIGHT / 2;
    this.object.add(this.pillar.object);
  }


  animate() {
    if(this.moveAni)
    {
      this.moveAni.pause();
    }

    const duration = getRandomArbitrary(3, 8);
    const y = getRandomArbitrary(.2, .5);

    this.moveAni = gsap.to(this.object.position, {y: y, duration: duration, ease: 'Cubic.easeInOut', onComplete: () => this.animate()});
  }

  activate() {
    if(!this.isActive)
    {
      this.isActive = true;
      this.pillar.showAnimation();
    }
  }

  deactivate() {
    if(this.isActive === true)
    {
      this.isActive = false;
      this.pillar.hideAnimation();
    }
  }

  setViewed(){
    this.pillar.setViewed();
  }

  getClusterColor() {
    let color;
    switch (this.cluster)
    {
      case 'tech':
        color = AppColors.TECH;
        break;
      case 'orga':
        color = AppColors.ORGA;
        break;
      case 'culture':
        color = AppColors.CULTURE;
        break;
    }

    return color;
  }

}

export {BigHexagon};
