import React from 'react';
import {useClusterColor} from '../hooks/useClusterColor';
import {StartButton} from '../StartButton';


const LayerCard = (props) =>
{
  const hoverColor = useClusterColor(props.cluster);

  const renderLink = () =>
  {
    if(props.cardData.link)
    {
      return <StartButton caption={'Show Video'} href={props.cardData.link.href} hoverColor={hoverColor} />;
    }
    else
    {
      return null;
    }
  };


  return (
    <div className={'itemCard'}>
      <h2>{props.cardData.title}</h2>
      <p dangerouslySetInnerHTML={{__html: props.cardData.copy}} />
      {renderLink()}
    </div>
  );
};

export {LayerCard};
