import {gsap} from 'gsap';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import {ReactComponent as ArrowIcon} from '../assets/arrow.svg';


const StartButton = (props) =>
{
  const refStartBtn = useRef(null);
  const refIcon = useRef(null);


  useEffect(() =>
  {
    if(props.show)
    {
      gsap.to(refStartBtn.current, {autoAlpha: 1, duration: .3});
    }
  }, [props.show]);

  useEffect(() =>
  {
    gsap.set(refStartBtn.current, {backgroundColor: props.hoverColor, color: '#000000'});
    gsap.set(refIcon.current, {fill: '#000000'});

  }, [props.hoverColor, refStartBtn, refIcon]);

  const onMouseEnter = () =>
  {
    gsap.to(refStartBtn.current, {backgroundColor: '#1f1f1f', duration: .3, color: props.hoverColor});
    gsap.to(refIcon.current, {fill: props.hoverColor, x: 0, duration: .3});
    gsap.to(refIcon.current, {x: 10, duration: .3, yoyo: true, repeat: -1, repeatDelay: .1});
  };

  const onMouseLeave = () =>
  {
    gsap.killTweensOf(refIcon.current);
    gsap.to(refStartBtn.current, {backgroundColor: props.hoverColor, duration: .3, color: '#000000'});
    gsap.to(refIcon.current, {fill: '#000000', x: 0, duration: .3});
  };

  const onClick = () =>
  {
    if(props.href)
    {
      window.open(props.href, '_blank');
    }

    if(props.onClick)
    {
      props.onClick();
    }
  };

  return (
    <>
      <div className={'button'} onClick={onClick} ref={refStartBtn} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>{props.caption} <ArrowIcon ref={refIcon} /></div>
    </>
  );
};

StartButton.propTypes = {
  caption   : PropTypes.string,
  onClick   : PropTypes.func,
  href      : PropTypes.string,
  hoverColor: PropTypes.string
};

StartButton.defaultProps = {
  hoverColor: '#000000'
};

export {StartButton};
